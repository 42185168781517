export default class Engine {
	constructor(player, camera, renderer, scene, texturePacks, models, onBeforeRenders, instancedScene) {
		this.internals = {
			camera: camera,
			renderer: renderer,
			scene: scene,
		}
    this.player = player;

    this.texturePacks = texturePacks;
    this.models = models;
    this.onBeforeRenders = onBeforeRenders;

		this.instancedScene = instancedScene;
		scene.add(this.instancedScene);
	}

	useLocalPlayer() {
		return this.player;
	}

	useInternals() {
		return this.internals;
	}

	useTexturePacks() {
		return this.texturePacks;
	}

	useModlePacks() {
		return this.models;
	}

	useOnBeforeRenders() {
		return this.onBeforeRenders;
	}

	useInstancedScene() {
		return this.instancedScene;
	}
}