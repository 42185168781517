export const customStatueShader = (material, uniforms) => {
  const customStatueMaterial = material.clone();

  customStatueMaterial.uniforms = uniforms;
  
  customStatueMaterial.onBeforeCompile = shader => {
    shader.uniforms.eye = uniforms.eye;
    shader.uniforms.isDay = uniforms.isDay;
    shader.uniforms.sunPosition = uniforms.sunPosition;

    shader.vertexShader = shader.vertexShader.replace(
      `#include <uv_pars_vertex>`,
      `
      #include <uv_pars_vertex>
      uniform vec3 eye;

      varying vec3 vSurfaceNormal;
      varying vec3 vWorldPosition;

      varying vec3 vViewDirection;
      varying vec3 vWorldNormal;
      varying vec3 vViewNormal;
      `,
    );

    shader.vertexShader = shader.vertexShader.replace(
      `void main() {`,
      `
      void main() {
        vSurfaceNormal = normalize(mat3(modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz) * normal);

        vec4 modelPosition = modelMatrix * vec4(position, 1.0);
        vViewDirection = normalize(modelPosition.xyz - eye);
        vWorldNormal = normalize(mat3(modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz) * normal);
        vViewNormal = normalize(normalMatrix * normal);
      `,
    );
    shader.vertexShader = shader.vertexShader.replace(
      `#include <worldpos_vertex>`,
      `
      #include <worldpos_vertex>
      vWorldPosition = transformed;
      `,
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <map_fragment>`,
      `
      #ifdef USE_MAP
        // vec4 sampledDiffuseColor = texture2D( map, vUv );
        vec4 sampledDiffuseColor = texture2D( map, mix(vWorldPosition.xz, vWorldPosition.zy, step(vSurfaceNormal.y, 0.1)) * 1.0 );
        #ifdef DECODE_VIDEO_TEXTURE
          // inline sRGB decode (TODO: Remove code when https://crbug.com/1256340 is solved)
          sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.w );
        #endif
       
        diffuseColor *= sampledDiffuseColor * 0.7 * (1.0 - vSurfaceNormal.y);
      #endif
      `,
    );
    
    shader.fragmentShader = shader.fragmentShader.replace(
      `void main() {`,
      `
      float getSunReflection(vec3 viewDirection, vec3 worldNormal, vec3 viewNormal) {
        float uFresnelOffset = 0.3;
        float uFresnelScale = 2.0;
        float uFresnelPower = 2.0;
    
        float sunMoon = isDay ? 1. : -1.;
        vec3 sunViewReflection = reflect(sunPosition * sunMoon, viewNormal);
        float sunViewStrength = max(0.2, dot(sunViewReflection, viewDirection));
    
        float fresnel = uFresnelOffset + uFresnelScale * (1.0 + dot(viewDirection, worldNormal));
        float sunReflection = fresnel * sunViewStrength;
        sunReflection = pow(sunReflection, uFresnelPower);
    
        return sunReflection;
      }
    
      vec3 getSunReflectionColor(vec3 baseColor, float sunReflection) {
        vec3 lightColor = isDay ? vec3(1.0, 1.0, 1.0) : vec3(0.7, 0.7, 0.7);
        return mix(baseColor, lightColor, clamp(sunReflection, 0.0, 1.0));
      }
    
      void main() {
       
      `,
    );

    

    

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <uv_pars_fragment>`,
      `
      #include <uv_pars_fragment>
      varying vec3 vSurfaceNormal;
      varying vec3 vWorldPosition;

      varying vec3 vViewDirection;
      varying vec3 vWorldNormal;
      varying vec3 vViewNormal;

      uniform vec3 sunPosition;
      uniform bool isDay;
      uniform vec3 eye;
      `,
    );
    
   
    shader.fragmentShader = shader.fragmentShader.replace(
      `vec4 diffuseColor = vec4( diffuse, opacity );`,
      `
        vec3 col = diffuse;
        // col.r -= 0.3;

        vec3 lightPos = isDay ? sunPosition : -sunPosition;
        vec3 lightDir = normalize(lightPos);
        
        float lambertTerm = dot(vSurfaceNormal, lightDir);
        float specularPow = 5.0;
        vec3 E = normalize(eye - vWorldPosition);
        vec3 R = reflect(lightDir, vSurfaceNormal);
        float specularTerm = pow(max(dot(R, E), 0.0), specularPow);
        float EDotN = clamp(dot(E, vSurfaceNormal), 0.0, 1.0);

        vec3 ambientColor = vec3(0.1, 0.1, 0.1);
        vec3 lightColor = vec3(1.0);
        float diffuseStrength = 20.0;
        vec3 lightDiffuse = lightColor * vec3(lambertTerm * diffuseStrength);
        float specularMultiplier = 5.0;
        vec3 specular = lightColor * vec3(specularTerm * specularMultiplier);

        

        vec3 finalLight = ambientColor + lightDiffuse + specular;
        finalLight = clamp(finalLight, 0.5, 1.0);
        col *= finalLight;

        float sunShade = dot(vec3(0.0, 1.0, 0.0), - sunPosition);
        sunShade = sunShade * 0.5 + 0.5;
        vec3 shadeColor = col * vec3(0.3, 0.5, 0.7);
        col = mix(col, shadeColor, sunShade);

        float sunReflection = getSunReflection(vViewDirection, vWorldNormal, vViewNormal);
        // col.rgb = col + clamp(sunReflection, 0.0, 0.5);
        col.rgb = getSunReflectionColor(col.rgb, sunReflection);



        vec4 diffuseColor = vec4(col, opacity);
      `,
    );
    // console.log(shader.vertexShader);
    // console.log(shader.fragmentShader);
  };
  return customStatueMaterial;
}