import * as THREE from 'three'

const SWORD_COMBO_CLAMP_INFO = {
  'COMBO1': 0.3, 
  'COMBO2': 0.5, 
  'COMBO3': 0.9
}


export class ActionsManager {
	constructor(avatar) {
    function extractLastSegment(inputString) {
      const lastPipeIndex = inputString.lastIndexOf('|');
      const result = inputString.substring(lastPipeIndex + 1).trim();
      return result;
    }

    const findAnimationByName = (name) => {
      return avatar.animations.find(animation => extractLastSegment(animation.name) === name)
    }
    
    this.actions = {};
    this.mixer = new THREE.AnimationMixer(avatar);
    const walkAction = this.mixer.clipAction(findAnimationByName('walking'));
    walkAction.play();
    this.actions.walk = walkAction;

    const swordWalkAction = this.mixer.clipAction(findAnimationByName('swordWalking'));
    swordWalkAction.play();
    this.actions.swordWalk = swordWalkAction;

    const idleAction = this.mixer.clipAction(findAnimationByName('idle'));
    this.actions.idle = idleAction;
    idleAction.play();

    const runAction = this.mixer.clipAction(findAnimationByName('running'));
    this.actions.run = runAction;
    runAction.play();

    const jumpAction = this.mixer.clipAction(findAnimationByName('jump'));
    this.actions.jump = jumpAction;
    jumpAction.play();

    const swimAction = this.mixer.clipAction(findAnimationByName('swim'));
    this.actions.swim = swimAction;
    swimAction.play();

    const floatAction = this.mixer.clipAction(findAnimationByName('float'));
    this.actions.float = floatAction;
    floatAction.play();

    const sitAction = this.mixer.clipAction(findAnimationByName('sit'));
    this.actions.sit = sitAction;
    sitAction.play();

    const swordDrawAction = this.mixer.clipAction(findAnimationByName('swordDraw'));
    swordDrawAction.play();
    this.actions.swordDraw = swordDrawAction;

    const swordComboAction = this.mixer.clipAction(findAnimationByName('swordCombo'));
    swordComboAction.play();
    this.actions.swordCombo = swordComboAction;
    
    this.setWeight(walkAction, 0)
    this.setWeight(swordWalkAction, 0)
    this.setWeight(idleAction, 0)
    this.setWeight(runAction, 0)
    this.setWeight(jumpAction, 0)
    this.setWeight(swimAction, 0)
    this.setWeight(floatAction, 0)
    this.setWeight(sitAction, 0)
    this.setWeight(swordDrawAction, 0)
    this.setWeight(swordComboAction, 0)
    
    this.currentAction = 'walk';

    this.swordComboClampThreshold = 0.3;
	}
  setSwordCombo(comboCount) {
    if (comboCount === 1) {
      this.swordComboClampThreshold = SWORD_COMBO_CLAMP_INFO.COMBO1;
    } else if (comboCount === 2) {
      this.swordComboClampThreshold = SWORD_COMBO_CLAMP_INFO.COMBO2;
    } else if (comboCount > 2) {
      this.swordComboClampThreshold = SWORD_COMBO_CLAMP_INFO.COMBO3;
    }
    this.setCurrentAction('swordCombo');

  }

  setWeight(action, weight) {
    action.enabled = true;
    action.setEffectiveTimeScale(1);
    action.setEffectiveWeight(weight);
  }

  executeCrossFade(startAction, endAction, duration) {
    this.setWeight(endAction, 1);
    endAction.time = 0;

    startAction.crossFadeTo(endAction, duration, true);
  }

  setCurrentAction(action) {
    if (this.currentAction === 'swordCombo') {
      const swordComboAction = this.actions[this.currentAction];
      if (swordComboAction.isRunning()) {
        const totalDuration = swordComboAction._clip.duration; 
        if (swordComboAction.time / totalDuration > this.swordComboClampThreshold) {
          window.hooks.emit('animation:finish_attacking');
        }
      }
    }
    if (this.currentAction !== action) {
      if (action === 'swordCombo') {
        if (this.currentAction === 'idle') {
          this.executeCrossFade(this.actions[this.currentAction], this.actions[action], 0.5);
        } else {
          this.executeCrossFade(this.actions[this.currentAction], this.actions[action], 0.2);
        }
      } else {
        if (action === 'jump' || action === 'swordDraw') {
          this.executeCrossFade(this.actions[this.currentAction], this.actions[action], 0.);
        } else {
          this.executeCrossFade(this.actions[this.currentAction], this.actions[action], 0.5);
        }
      }
      
      
    }
    this.currentAction = action;
  }

  update(timeDiff) {
    // this.actions[this.currentAction] && this.actions[this.currentAction].update(timeDiff / 1000);

    this.mixer.update( timeDiff / 1000 );
  }
}