export const uiStart = () => {};

const ui = document.getElementById('ui');
const overLay = document.getElementById('main-overlay');

// loading page
const loadingContainer = document.getElementById('loading-container');

// finish loading
window.hooks.on('app_loaded', () => {
  loadingContainer.style.display = 'none';
  
  overLay.style.display = 'flex'; 
  overLay.style.backgroundColor = 'rgba(0, 0, 0, 1.0)';

  const clickToContinueText = document.createElement('div');
  clickToContinueText.textContent = '- Click to continue -';
  clickToContinueText.style.position = 'absolute';
  clickToContinueText.style.bottom = '50%';
  clickToContinueText.style.left = '50%';
  clickToContinueText.style.transform = 'translateX(-50%)';
  clickToContinueText.style.color = 'rgb(255, 255, 255)';
  clickToContinueText.style.zIndex = '10';
  clickToContinueText.style.textAlign = 'center';
  clickToContinueText.style.fontSize = '32px';
  clickToContinueText.style.whiteSpace = 'nowrap'
  clickToContinueText.classList.add('clickText-fade-in');
  overLay.appendChild(clickToContinueText);
  

  const removeOverlayAndStartGame = () => {
    overLay.classList.add('startButton-fade-out');
    setTimeout(() => {
      overLay.style.display = 'none';
      overLay.classList.remove('startButton-fade-out');
      window.dispatchEvent(new Event('resize'));
    }, 2200); 
  
    const timeInterVal = setInterval(() => {
      if (window.gameManagers.game.grounded) { 
        window.hooks.emit('cameraFocus:desk');
        window.clearInterval(timeInterVal);
      }
    }, 10); 
  
    overLay.removeChild(clickToContinueText);
    window.gameManagers.game.startGame();
    window.gameStart = true;
  }

  overLay.addEventListener('touchstart', touchStartHandler);
  function touchStartHandler() {
    overLay.removeEventListener('touchstart', touchStartHandler);
    isMobile = true;
    window.gameManagers.ioManager.setTouch();
    removeOverlayAndStartGame();
  }

  overLay.addEventListener('click', keydownHandler);
  function keydownHandler() {
    overLay.removeEventListener('click', keydownHandler);
    if (window.gameStart) {
      return;
    }
    removeOverlayAndStartGame();
  }
});


// show interact spot text
const interactSpotText = document.getElementById('interact-spot-text');

window.hooks.on('interactspot_text', (text) => {
  if (text) {
    interactSpotText.textContent = text;
  } else {
    interactSpotText.textContent = '';
  }
  
});

// panel settings ui
const volumeCheckBox = document.getElementById('volumeCheckBox');
const volumeCheckBoxOption = document.getElementById('volumeCheckBoxOption');
volumeCheckBox.addEventListener('click', function(event) {
    event.stopPropagation();
    if (volumeCheckBox.checked) {
        volumeCheckBoxOption.classList.remove('checked');
    } else {
        volumeCheckBoxOption.classList.add('checked');
    } 
    window.hooks.emit('toggle-volume', volumeCheckBox.checked);
});

const shadowCheckBox = document.getElementById('shadowCheckBox');
const shadowCheckBoxOption = document.getElementById('shadowCheckBoxOption');
shadowCheckBox.addEventListener('click', function(event) {
    event.stopPropagation();
    if (shadowCheckBox.checked) {
        shadowCheckBoxOption.classList.remove('checked');
    } else {
        shadowCheckBoxOption.classList.add('checked');
    } 
    window.hooks.emit('toggle-shadow', shadowCheckBox.checked);
});

if(isMobile) {
  shadowCheckBoxOption.classList.remove('checked');
}

const waterCheckBox = document.getElementById('waterCheckBox');
const waterCheckBoxOption = document.getElementById('waterCheckBoxOption');
waterCheckBox.addEventListener('click', function(event) {
    event.stopPropagation();
    if (waterCheckBox.checked) {
        waterCheckBoxOption.classList.remove('checked');
    } else {
        waterCheckBoxOption.classList.add('checked');
    } 
    window.hooks.emit('toggle-water', waterCheckBox.checked);
});

const qualityDropdown = document.getElementById('qualityDropdown');
qualityDropdown.addEventListener('change', function() {
  const selectedValue = qualityDropdown.value;
  window.hooks.emit('grass-quality', selectedValue);
});

const settingsPanel = document.getElementById('settingsPanel');
settingsPanel.addEventListener('click', function(event) {
    event.stopPropagation();
    const popup = document.querySelector('.settings-popup');
    if (popup.classList.contains('hidden')) {
      popup.classList.remove('hidden');
      popup.classList.add('shown');
      window.dispatchEvent(new Event('open-settings-panel'));

      const settingsImg = document.getElementById('settingsImage');
      if (!settingsImg.classList.contains('imageRotated')) {
        settingsImg.classList.add('imageRotated');
        settingsImg.classList.add('active');
      }
    } else {
      popup.classList.remove('shown');
      popup.classList.add('hidden');
      window.dispatchEvent(new Event('close-settings-panel'));
      const settingsImg = document.getElementById('settingsImage');
      if (settingsImg.classList.contains('imageRotated')) {
        settingsImg.classList.remove('imageRotated');
        settingsImg.classList.remove('active');
      }
    }

    const tooltip = document.getElementById('tooltip');
    if (!tooltip.classList.contains('hidden')) {
        tooltip.classList.add('hidden');
    }
});

const settingscloseBtn = document.getElementById('settings-popup-close-btn');
settingscloseBtn.addEventListener('click', function(event) {
    event.stopPropagation();
    const popup = document.querySelector('.settings-popup');
    popup.classList.remove('shown');
    popup.classList.add('hidden');
    window.dispatchEvent(new Event('close-settings-panel'));
    const settingsImg = document.getElementById('settingsImage');
    if (settingsImg.classList.contains('imageRotated')) {
      settingsImg.classList.remove('imageRotated');
      settingsImg.classList.remove('active');
    }
    
    
});

// setTimeout(() => {
//     const tooltip = document.getElementById('tooltip');
//     if (!tooltip.classList.contains('hidden')) {
//         tooltip.classList.add('hidden');
//     }
// }, 180000)


document.getElementById('zoomSlider').addEventListener('input', function(e) {
  // e.preventDefault();
  const minValue = -2.6;
  const maxValue = 0;
  const newValue = this.value / 100 * (maxValue - minValue) + minValue;
  window.gameManagers.cameraManager.setCameraOffset(newValue.toFixed(2));
});

