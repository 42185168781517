import * as THREE from 'three';
import { fireflyVertexShader, fireflyFragmentShader } from '../Material/firefly/shader';

export const getFireflies = (particleCount, player, camera, texturePacks) => {

  const _getGeometry = (geometry, attributeSpecs, particleCount) => {
    const geometry2 = new THREE.BufferGeometry();
    ['position', 'normal', 'uv'].forEach(k => {
    geometry2.setAttribute(k, geometry.attributes[k]);
    });
    geometry2.setIndex(geometry.index);

    const positions = new Float32Array(particleCount * 3);
    const positionsAttribute = new THREE.InstancedBufferAttribute(positions, 3);
    geometry2.setAttribute('positions', positionsAttribute);

    for(const attributeSpec of attributeSpecs){
      const {
        name,
        itemSize,
      } = attributeSpec;
      const array = new Float32Array(particleCount * itemSize);
      geometry2.setAttribute(name, new THREE.InstancedBufferAttribute(array, itemSize));
    }

    return geometry2;
  };
  const attributeSpecs = [];
  attributeSpecs.push({name: 'scales', itemSize: 1});
  attributeSpecs.push({name: 'opacity', itemSize: 1});

  const geometry2 = new THREE.PlaneGeometry(0.12, 0.12);
  const geometry = _getGeometry(geometry2, attributeSpecs, particleCount);

  const getTexureByName = (textureName) => {
    return texturePacks.find(x => x.name === textureName).texture;
  }

  const material = new THREE.ShaderMaterial({
    uniforms: {
      uTime: { value: 0 },
      cameraBillboardQuaternion: {
        value: new THREE.Quaternion(),
      },
      glowSpheretexture: {
        value: getTexureByName('glowSphere')
      },
      isDay: {
        value: true
      },
      eye: {
        value: new THREE.Vector3(),
      }
    },
    vertexShader: fireflyVertexShader,
    fragmentShader: fireflyFragmentShader,
    side: THREE.DoubleSide,
    // transparent: true,
    depthWrite: false,
    blending: THREE.AdditiveBlending
  });

  const fireFlies = new THREE.InstancedMesh(geometry, material, particleCount);

  const info = {
    velocity: [particleCount],
    flashSpeed: [particleCount],
  }
  for (let i = 0; i < particleCount; i ++) {
    info.velocity[i] = new THREE.Vector3();
    info.flashSpeed[i] = Math.random();
  }

  const maxFireflyDistance = 100;
  let azimuth = 0.4;
  fireFlies.update = (timestamp, isDay) => {
    azimuth = (0.05 + (Date.now() / 5000) * 0.1) % 1;
    const scalesAttribute = fireFlies.geometry.getAttribute('scales');
    const opacityAttribute = fireFlies.geometry.getAttribute('opacity');
    const positionsAttribute = fireFlies.geometry.getAttribute('positions');
    const visible = window.gameManagers.reginTrackerManager.region !== 'beach' && window.gameManagers.reginTrackerManager.region !== 'homespace_exterior';
    
    for (let i = 0; i < particleCount; i++) {
      if (scalesAttribute.getX(i) <= 0. && visible) {
        info.velocity[i].set(
          Math.random() - 0.5, Math.random() - 0.5, Math.random() - 0.5
        ).divideScalar(100);
        info.flashSpeed[i] = Math.random();
        scalesAttribute.setX(i, Math.random());
        positionsAttribute.setXYZ(i,
          player.position.x + (Math.random() - 0.5) * maxFireflyDistance, 
          player.position.y + (Math.random() - 0.5) * 2, 
          player.position.z + (Math.random() - 0.5) * maxFireflyDistance
        );

      }
      positionsAttribute.setXYZ(
        i,
        positionsAttribute.getX(i) + info.velocity[i].x,
        positionsAttribute.getY(i) + info.velocity[i].y,
        positionsAttribute.getZ(i) + info.velocity[i].z
      )
      if (scalesAttribute.getX(i) > 0) {
        scalesAttribute.setX(i, scalesAttribute.getX(i) - 0.003);
      } else {
        scalesAttribute.setX(i, 0);
      }
      
      opacityAttribute.setX(i, Math.abs(Math.cos(timestamp * 0.0025 * info.flashSpeed[i])));
    }
    scalesAttribute.needsUpdate = true;
    positionsAttribute.needsUpdate = true;
    opacityAttribute.needsUpdate = true;
    
    material.uniforms.isDay.value = isDay;
    
    
    material.uniforms.uTime.value = timestamp / 1000;
    material.uniforms.cameraBillboardQuaternion.value.copy(camera.quaternion);   
    material.uniforms.eye.value.copy(camera.position);   

  }
  return fireFlies;
}