export class EventEmitter {
  constructor() {
      this.events = {};
  }

  // Register an event listener
  on(eventName, listener) {
      if (!this.events[eventName]) {
      this.events[eventName] = [];
      }
      this.events[eventName].push(listener);
  }

  // Emit an event
  emit(eventName, data) {
      if (this.events[eventName]) {
      this.events[eventName].forEach(listener => {
          listener(data);
      });
      }
  }
}
window.hooks = new EventEmitter();