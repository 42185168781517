import * as THREE from 'three'

import View from '../View.js';

import { customAvatarShader } from '../Material/avatar/custom-shader.js';
import { SUN_RISE_POSITION } from '../../../constants.js';

export default class AvatarManager {
  constructor() {
    this.view = View.getInstance();
    this.skyManager = this.view.skyManager
    this.player = this.view.player;

    this.avatarUniforms = {
      sunPosition: {
        value: new THREE.Vector3(SUN_RISE_POSITION[0], SUN_RISE_POSITION[1], SUN_RISE_POSITION[2])
      },
      eye: {
        value: new THREE.Vector3()
      },
      isDay: {
        value: true
      },
    };
    const model = this.player.avatar;
    model && model.traverse(o => {
      if (o.isMesh) {
        const customMaterial = customAvatarShader(o.material, this.avatarUniforms);
        o.material = customMaterial;
      }
    });

    window.hooks.on('current_region', data => {
      if (data === 'homespace_interior') {
        this.avatarUniforms.sunPosition.value.set(SUN_RISE_POSITION[0], SUN_RISE_POSITION[1], SUN_RISE_POSITION[2]);
        this.avatarUniforms.isDay.value = true;
        this.avatarUniforms.eye.value.copy(this.view.camera.position);
      }
    });
    
  }


  update() {
    this.avatarUniforms.sunPosition.value.copy(this.skyManager.sunPosition);
    this.avatarUniforms.eye.value.copy(this.view.camera.position);
    this.avatarUniforms.isDay.value = this.skyManager.isDay;
  }
}