import * as THREE from 'three';
// import * as CANNON from 'cannon-es';

import physicsManager from './physics/physics-manager';
import { WATER_HEIGHT, HOMESPACE_EXTERIOR_POSITION, HOMESPACE_INTERIOR_POSITION } from './constants';

const localVector = new THREE.Vector3();
const localVector2 = new THREE.Vector3();
const localVector3 = new THREE.Vector3();
const localVector4 = new THREE.Vector3();
const localVector5 = new THREE.Vector3();

const localQuaternion2 = new THREE.Quaternion();

const localVectorOfPosition = new THREE.Vector3(0, 0, 0);
const localVectorOfScale = new THREE.Vector3(1, 1, 1);
const localQuaternion = new THREE.Quaternion(0, 0, 0, 1);

const JUMP_STRENGTH_HEIGHT = 1.0; // jumping height
const JUMPING_INTERVAL_THRESHOLD = 1000; // prevent user keep jumping

export class GameManger {
  constructor() {
    this.player = window.gameEngine.useLocalPlayer();
    this.camera = window.gameEngine.useInternals().camera;
    
    
    this.lastPlayerPosition = new THREE.Vector3();
    this.lastPlayerPosition.copy(this.player.position);
    
    
    this.velocity = new THREE.Vector3();
    this.canJump = false;
    this.cameraTargetDir = new THREE.Vector3();
    this.cameraDir = new THREE.Vector3();

    this.rotateAngle = new THREE.Vector3(0, 1, 0)
    this.rotateQuarternion = new THREE.Quaternion()

    this.lastJumpTime = 0;

    this.physics = physicsManager.getScene();

    this.enableActive = true;

    this.loadCharacterController(1.0, 2);
    
    const handleTeleport = (position) => {
      localVector4.fromArray(position)
      this.physics.setCharacterControllerPosition(this.characterController, localVector4)

      const overLay = document.getElementById('main-overlay');
      overLay.style.display = 'flex'; 
      overLay.style.backgroundColor = 'rgba(0, 0, 0, 1.0)';
      overLay.classList.add('fade-out');
      const loadingContainer = document.getElementById('loading-container');
      loadingContainer.style.display = '';

      setTimeout(() => {
        loadingContainer.style.display = 'none'
      }, 3000); 
      setTimeout(() => {
        overLay.style.display = 'none';
        overLay.classList.remove('fade-out');
      }, 6000); 
    }

    window.hooks.on('teleport:leave_homespace', () => {
      handleTeleport([-858.4012451171874, 7.803129196166994, -1130.3618164062502]);
    });

    window.hooks.on('teleport:enter_homespace', () => {
      handleTeleport([-867.8126220703126, 505.20928955078114, -1089.5240478515625]);
    });

    this.lastAttackTime = 0;
    this.attackLock = false; // set this lock since I don't want to blend another animation with attack

    this.grounded = false;
  }

  handleDeskFocus() {
    function createPortfolioOverlay() {
      var overlay = document.createElement('div');
      overlay.className = 'portfolio-overlay';
      overlay.id = 'portfolio-overlay';
  
      var container = document.createElement('div');
      container.className = 'portfolio-container';
      container.id = 'portfolio-container';
  
      var closeButton = document.createElement('span');
      closeButton.className = 'portfolio-close-symbol';
      closeButton.id = 'portfolio-close-symbol';
      closeButton.innerHTML = '&times;';
      closeButton.onclick = function() { removePortfolioOverlay();  window.hooks.emit('cameraFocus:desk')};
  
      var iframe = document.createElement('iframe');
      iframe.className = 'portfolio-iframe';
      iframe.id = 'portfolio-iframe';
      iframe.src = 'https://tcm390.github.io/test-windows-ui/';
      iframe.style.border = '0';
      iframe.style.overflow = 'hidden';
  
      setTimeout(() => {
        container.appendChild(closeButton);
      }, 600)
      container.appendChild(iframe);
      overlay.appendChild(container);
      document.body.appendChild(overlay);
      container.classList.add('portfolio-maximize');
    }
  
    function removePortfolioOverlay() {
      var overlay = document.getElementById('portfolio-overlay');
      if (overlay) {
        overlay.parentNode.removeChild(overlay);
      }
    }
  
    if (!window.gameManagers.reginTrackerManager.focusDesk) {
      localVector5.set(-867.7576904296876, 501.1277770996094, -1112.2931884765627);
      this.player.quaternion.set(0, 0.9929358402267545, 0, 0.024857386163745866);
      this.physics.setCharacterControllerPosition(this.characterController, localVector5)
      removePortfolioOverlay();
    } else {
      createPortfolioOverlay();
    }
  }

  startGame() {
    window.gameManagers.ioManager.setUpListner();
    const renderer = window.gameEngine.useInternals().renderer;
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.player.avatar.visible = true;
  }

  async loadCharacterController(characterWidth, characterHeight) {
    this.characterWidth = characterWidth;
    this.characterHeight = characterHeight;

    this.capsuleWidth = characterWidth / 2;
    this.capsuleHeight = characterHeight - characterWidth;

    const contactOffset = 0.01 * this.capsuleHeight;
    const stepOffset = 0.1 * this.capsuleHeight;

    const position = this.player.position.clone();

    this.characterController = this.physics.createCharacterController(
      this.capsuleWidth,
      this.capsuleHeight,
      contactOffset,
      stepOffset,
      position
    );
    window.gameEngine.useInternals().scene.add(this.characterController)
  }

  getSpeed() {
    const ioManager = window.gameManagers.ioManager;
    let speed = this.ismoving ? 0.1 : 0;
    const isRunning = ioManager.keys.shift;
    const isJumping = window.gameManagers.actionsManager.currentAction === 'jump';
    const sprintMultiplier = this.attackLock ? (isRunning ? 0.15 : 0.05) :((isJumping) ? 0.5 : (isRunning ? 1.5 : (!this.inWater ? 0.5 : 1)));
    speed *= sprintMultiplier;
    return speed;
  }

  applyAnimation(timestamp) {
    const ioManager = window.gameManagers.ioManager;
    const isRunning = ioManager.keys.shift;
    const enableJumping = this.velocity.y >= JUMP_STRENGTH_HEIGHT;
    const isAttacking = window.gameManagers.weaponManager.attacking;

    if (this.inWater) {
      
      if (!this.ismoving) {
        const targetHeight = -1.2 - (this.player.position.y - WATER_HEIGHT); // hack should adjust in blender
        this.player.avatar.position.y = this.lerp(this.player.avatar.position.y, targetHeight, 0.1);
        window.gameManagers.actionsManager.setCurrentAction('float');
      } else {
        const targetHeight = -0.8 - (this.player.position.y - WATER_HEIGHT); // hack should adjust in blender
        this.player.avatar.position.y = this.lerp(this.player.avatar.position.y, targetHeight, 0.1);
        window.gameManagers.actionsManager.setCurrentAction('swim');
      }
    } else {
      const targetHeight = -1; // hack should adjust in blender
      this.player.avatar.position.y = this.lerp(this.player.avatar.position.y, targetHeight, 0.1);

      if (isAttacking) {
        this.attackLock = true;
        const comboCount = window.gameManagers.weaponManager.comboCount;
        window.gameManagers.actionsManager.setSwordCombo(comboCount);
        
      } else {
        if (this.attackLock) {
          window.gameManagers.actionsManager.setCurrentAction('idle');
        } else {
          if (this.canJump) {
            if (!this.ismoving) {
              window.gameManagers.actionsManager.setCurrentAction('idle');
            } else {
              if (isRunning) {
                window.gameManagers.actionsManager.setCurrentAction('run');
              } else {
                if (this.player.wearing) {
                  window.gameManagers.actionsManager.setCurrentAction('swordWalk');
                } else {
                  window.gameManagers.actionsManager.setCurrentAction('walk');
                }
               
              }
            }
          } else {
            if (enableJumping) {
              window.gameManagers.actionsManager.setCurrentAction('jump');
            }
          }
        }
      } 
    }

    if (isAttacking) {
      this.lastAttackTime = timestamp;
    }

    if (timestamp - this.lastAttackTime > 500) {
      this.attackLock = false;
    }

    
  }

  lerp(start, end, t) {
    return start * (1 - t) + end * t;
  }
 
  update(timestamp, timeDiff) {
    if (!window.gameStart) {
      return;
    }
    
    const timeDiffS = timeDiff / 1000;
    const focusDesk = window.gameManagers.reginTrackerManager.focusDesk;
    if (focusDesk) {
      localVector5.set(-867.6911010742189, 501.47780761718744, -1112.856103515625);
      this.player.quaternion.set(0, 0.9929358402267545, 0, 0.024857386163745866);
      window.gameManagers.actionsManager.setCurrentAction('sit');
      this.physics.setCharacterControllerPosition(this.characterController, localVector5)
     
      // return;
    }
   
    const cameraManager = window.gameManagers.cameraManager;
    const ioManager = window.gameManagers.ioManager;

    const chunks = window.gameManagers.procgenManager.state.chunks;
    const currentGroundHeight = chunks.getElevationForPosition(this.player.position.x, this.player.position.z);

    if (this.player.position.y < currentGroundHeight) {
      localVector3.set(this.player.position.x, currentGroundHeight + 30, this.player.position.z);
      this.physics.setCharacterControllerPosition(this.characterController, localVector3)
    }

    this.ismoving = isMobile ? ioManager.touch.joystick.active : ioManager.keys.up || ioManager.keys.down || ioManager.keys.right || ioManager.keys.left;
    this.inWater = this.player.position.y < WATER_HEIGHT;
    
    if (this.ismoving) {
      const angleYCameraDirection = Math.atan2(
        (this.lastPlayerPosition.x - this.characterController.position.x), 
        (this.lastPlayerPosition.z - this.characterController.position.z)
      );
      // diagonal movement angle offset
      const directionOffset = ioManager.getDirectionOffset();
      // rotate player
      
      this.rotateQuarternion.setFromAxisAngle(this.rotateAngle, angleYCameraDirection + directionOffset);
      localQuaternion2.set(0, cameraManager.targetQuaternion.y, 0, cameraManager.targetQuaternion.w);
      this.rotateQuarternion.premultiply(localQuaternion2);
      this.player.quaternion.rotateTowards(this.rotateQuarternion, 0.2);
      
      // get cameraTarget direction
      localVector.set(0, 0, -1);
      this.cameraTargetDir = localVector.applyQuaternion(cameraManager.targetQuaternion);
      this.cameraTargetDir.normalize();
      this.cameraTargetDir.applyAxisAngle(this.rotateAngle, directionOffset);
    }
    
    let movingSpeed = this.getSpeed();
    // move player
    const moveX = this.cameraTargetDir.x * movingSpeed;
    const moveZ = this.cameraTargetDir.z * movingSpeed;
    if (this.inWater) {
      this.characterController.position.y = WATER_HEIGHT;
      this.velocity.y = 0;
    }
    localVector2.set(moveX, this.velocity.y, moveZ);
    // localVector2.y +=1;
    const flags = this.physics.moveCharacterController(
      this.characterController,
      localVector2,
      0,
      0,
      this.characterController.position
    );

    if (!focusDesk) {
      let grounded = !!(flags & 0x1);
      this.grounded = grounded;
      if (grounded) {
        this.velocity.y = 0;
        this.canJump = true;
      } else {
        this.velocity.y -= 0.098;
      }
      
  
      if (this.canJump && ioManager.keys.space && !ioManager.jumpLock && timestamp - this.lastJumpTime > JUMPING_INTERVAL_THRESHOLD) {
        this.velocity.y = JUMP_STRENGTH_HEIGHT;
        this.canJump = false;
        ioManager.jumpLock = true;
        this.lastJumpTime = timestamp;
      }

      this.applyAnimation(timestamp);
    }
    
   
    // this.player.position.x = this.characterController.position.x;
    // this.player.position.z = this.characterController.position.z;
    this.player.position.x = this.lerp(this.characterController.position.x, this.player.position.x, 0.6);
    this.player.position.z = this.lerp(this.characterController.position.z, this.player.position.z, 0.6);
    this.player.position.y = this.lerp(this.characterController.position.y, this.player.position.y, 0.8); // prevent vibrate while walking

    

    // this.characterController.position.y += 1.;
    this.characterController.updateMatrixWorld();
    // this.cp && this.cp.updateMatrixWorld();
    
    this.lastPlayerPosition.x = this.characterController.position.x;
    this.lastPlayerPosition.z = this.characterController.position.z;



    // handle interact key down
    if (!ioManager.keys.interact) {
      this.enableActive = true;
    }
    
    if (ioManager.keys.interact && this.enableActive) {
      this.enableActive = false;
      const spot = window.gameManagers.reginTrackerManager.currentInteractSpot;
      if (spot) {
        window.hooks.emit(spot.hooks);
      }
    }
  }

}
