export const AVATAR_HEIGHT = 0.5;
export const WATER_HEIGHT = 0;

export const HOKAGE_POSITION = [-848.2824096680696, -1153.4020996093745];
export const HOKAGE_RADIUS = 70;

export const SUN_RISE_POSITION = [-0.4300006280483834, 0.9023830969060987, -0.02835853127639427];

export const HOMESPACE_INTERIOR_POSITION = [-867.9671659690924, 500, -1110.7038528841067];
export const HOMESPACE_INTERIOR_RADIUS = 70;

export const HOMESPACE_EXTERIOR_POSITION = [-867.9671659690924, 2.2, -1110.7038528841067];
export const HOMESPACE_EXTERIOR_RADIUS = 70;

export const LEFT_SAKURA_POSITION = [-877.6593627929686, -1125.3809814453125];
export const RIGHT_SAKURA_POSITION = [-854.7161255111228, -1114.3791502699098];


export const INTERACT_SPOTS = [
  {
    'position': [-868.052490234375, 502.20928955078114, -1082.460693359375],
    'message': `- Press the E key to leave the home space -`,
    'hooks': 'teleport:leave_homespace',
    'radius': 3
  },
  {
    'position': [-854.5272216796877, 8.396778106689453, -1137.9705810546875],
    'message': `- Press the E key to enter the home space -`,
    'hooks': 'teleport:enter_homespace'
  },
  {
    'position': [-867.8500366210936, 501.1277770996094, -1111.8635253906255],
    'message': `- Press the E key to access my profile -`,
    'hooks': 'cameraFocus:desk',
    'radius': 3
  },

  
]

export const GRASS_QUALITY_TYPE = {
  HIGH: 'high',
  LOW: 'low',
  NONE: 'none',
}