import * as THREE from 'three';


import State from '../../State/State.js';

import View from '../View.js';

import { Water } from '../water-effect/water-mesh.js';
import { skySettings } from "../../utils/constants.js";

import ParticleManager from '../water-effect/particle-manager.js';
import { WATER_HEIGHT } from '../../../constants.js';

import { underWaterMaskVertexShader, underWaterMaskFragmentShader } from '../Material/underWaterMask/shader.js';

export default class WaterManager {
  constructor() {
    this.state = State.getInstance();
    this.view = View.getInstance();
    this.texturePacks = this.view.texturePacks;
    
    this.player = this.view.player;
    this.camera = this.view.camera;
    this.scene = this.view.scene;
    this.renderer = this.view.renderer;
    this.onBeforeRenders = this.view.onBeforeRenders;
    this.instancedScene = this.view.instancedScene;

    // underwater mask
    const underWaterMaskgeometry = new THREE.PlaneGeometry( 2, 2 );
    const underWaterMaskmaterial= new THREE.ShaderMaterial({
      uniforms: {
        waterHeight:{
          value: WATER_HEIGHT
        }
      },
      vertexShader: underWaterMaskVertexShader,
      fragmentShader: underWaterMaskFragmentShader,
      side: THREE.DoubleSide,
      transparent: true,
      depthWrite: false,
    });
    this.underWaterMask = new THREE.Mesh(underWaterMaskgeometry, underWaterMaskmaterial);
    this.underWaterMask.position.set(0, 0, -0.2);
    this.camera.add(this.underWaterMask);

    
    const oceanSize = 2000;
    this.ocean = new Water(oceanSize, this.player, this.camera, this.scene, this.renderer, this.instancedScene, this.underWaterMask, this.view);
    const renderDepth = () => this.ocean.renderDepth();
    this.onBeforeRenders.push(renderDepth)

    this.ocean.material.uniforms.waterNormalTexture.value = this.getTexureByName('waterNormal');

    this.ocean.material.uniforms.uDawnAngleAmplitude = skySettings.uDawnAngleAmplitude;
    this.ocean.material.uniforms.uDawnElevationAmplitude = skySettings.uDawnElevationAmplitude; 
    this.ocean.material.uniforms.uSunAmplitude = skySettings.uSunAmplitude;
    this.ocean.material.uniforms.uSunMultiplier = skySettings.uSunMultiplier;
    
    this.ocean.material.uniforms.uColorDayCycleLow = skySettings.uColorDayCycleLow;
    this.ocean.material.uniforms.uColorDayCycleHigh = skySettings.uColorDayCycleHigh;
    this.ocean.material.uniforms.uColorNightLow = skySettings.uColorNightLow;
    this.ocean.material.uniforms.uColorNightHigh = skySettings.uColorNightHigh;
    this.ocean.material.uniforms.uColorSun = skySettings.uColorSun;
    this.ocean.material.uniforms.uColorDawn = skySettings.uColorDawn;

    this.ocean.rotation.x = -Math.PI / 2;
    
    this.scene.add(this.ocean);

    

    this.waterPartclieMAnager = new ParticleManager(this.player, this.camera, this.instancedScene, this.texturePacks);


    
    
  }

  getTexureByName(textureName) {
    return this.texturePacks.find(x => x.name === textureName).texture;
  }

  update(timestamp) {
    // update ocean
    this.ocean.position.x = this.player.position.x;
    this.ocean.position.z = this.player.position.z;
    this.ocean.material.uniforms.uTime.value = timestamp / 1000;
    if (this.view.skyManager) {
      this.ocean.material.uniforms.sunPosition.value.copy(this.view.skyManager.sunPosition);
      if (this.view.skyManager.isDay) {
        this.ocean.material.uniforms.lightColor.value.set('#fff');
      }
      else {
        this.ocean.material.uniforms.lightColor.value.set('#1ab3e6');
      }
    }
    this.ocean.material.uniforms.uDayCycleProgress.value = this.view.skyManager.azimuth;
    this.ocean.material.uniforms.isDay.value = this.view.skyManager.isDay;
    this.ocean.material.uniforms.playerPosition.value.copy(this.view.player.position);

    // update water particle
    this.waterPartclieMAnager.update(timestamp);
  }
  
}