import * as THREE from 'three'

import View from '../View.js';

import { customHomespaceShader } from '../Material/homespace/custom-shader.js';
import { customSakuraShader } from '../Material/sakura/custom-shader.js';
import { customStatueShader } from '../Material/statue/custom-shader.js';
import { SUN_RISE_POSITION } from '../../../constants.js';

export default class HomeSpaceManager {
  constructor() {
    this.view = View.getInstance();
    this.skyManager = this.view.skyManager
    this.player = this.view.player;
    this.texturePacks = this.view.texturePacks;

    this.homespaceUniforms = {
      uTime: {
        value: 0
      },
      sunPosition: {
        value: new THREE.Vector3(SUN_RISE_POSITION[0], SUN_RISE_POSITION[1], SUN_RISE_POSITION[2])
      },
      eye: {
        value: new THREE.Vector3()
      },
      isDay: {
        value: true
      },
      noiseTexture: {
        value: this.getTexureByName('noise3')
      },
    };
    
    const homespaceExterior = this.player.homespaceExterior;
    homespaceExterior && homespaceExterior.traverse(o => {
      if (o.isMesh) {
        // console.log(o.name)
        if (o.name === "Mesh084_1" || o.name === "Mesh084_4") {
          const customMaterial = customSakuraShader(o.material, this.homespaceUniforms);
          o.material = customMaterial;
        } else {
          const customMaterial = customHomespaceShader(o.material, this.homespaceUniforms);
          o.material = customMaterial;
        }
      }
    });

    const homespaceInterior = this.player.homespaceInterior;
    homespaceInterior && homespaceInterior.traverse(o => {
      if (o.isMesh) {
        
        if (o.name === 'Level_Hf_Build_Comp_Base_01_Vo002_4' || o.name === 'Level_Hf_Build_Comp_Base_01_Vo002_5') {
          const customMaterial = customStatueShader(o.material, this.homespaceUniforms);
          o.material = customMaterial;
        } else {
          const customMaterial = customHomespaceShader(o.material, this.homespaceUniforms);
          o.material = customMaterial;
        }
      }
    });

    const sword = this.player.sword;
    sword && sword.traverse(o => {
      if (o.isMesh) {
        const customMaterial = customHomespaceShader(o.material, this.homespaceUniforms);
        o.material = customMaterial;
      }
    });

    window.hooks.on('current_region', data => {
      if (data === 'homespace_interior') {
        this.homespaceUniforms.sunPosition.value.set(SUN_RISE_POSITION[0], SUN_RISE_POSITION[1], SUN_RISE_POSITION[2]);
        this.homespaceUniforms.isDay.value = true;
        this.homespaceUniforms.eye.value.copy(this.view.camera.position);
      }
    });
    
  }
  getTexureByName(textureName) {
    return this.texturePacks.find(x => x.name === textureName).texture;
  }

  update(timestamp) {
    this.homespaceUniforms.sunPosition.value.copy(this.skyManager.sunPosition);
    this.homespaceUniforms.eye.value.copy(this.view.camera.position);
    this.homespaceUniforms.isDay.value = this.skyManager.isDay;
    this.homespaceUniforms.uTime.value = timestamp / 1000;
  }
}