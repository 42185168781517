export const customAvatarShader = (material, uniforms) => {
  const customAvatarMaterial = material.clone();

  customAvatarMaterial.uniforms = uniforms;
  
  customAvatarMaterial.onBeforeCompile = shader => {
    shader.uniforms.sunPosition = uniforms.sunPosition;

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <uv_pars_fragment>`,
      `
      #include <uv_pars_fragment>
      uniform vec3 sunPosition;
      
      `,
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <map_fragment>`,
      `
      #ifdef USE_MAP
        vec4 sampledDiffuseColor = texture2D( map, vUv );
        #ifdef DECODE_VIDEO_TEXTURE
          // inline sRGB decode (TODO: Remove code when https://crbug.com/1256340 is solved)
          sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.w );
        #endif
        if (sampledDiffuseColor.a < 0.1) {
          discard;
        }
        diffuseColor *= sampledDiffuseColor;
        diffuseColor.rgb += vec3(0.3, 0.5, 0.3);

        float sunShade = dot(vec3(0.0, 1.0, 0.0), - sunPosition);
        sunShade = sunShade * 0.5 + 0.5;
        vec3 shadeColor = diffuseColor.rgb * vec3(0.3, 0.5, 0.7);
        diffuseColor.rgb = mix(diffuseColor.rgb, shadeColor, sunShade);
      #endif
      `,
    );
    
  };
  return customAvatarMaterial;
}