import * as THREE from 'three';
import { customAvatarShader } from '../Material/butterfly/custom-shader.js';


export const getButterflies = (particleCount, player, texturePacks, globalUniforms) => {


  const getTexureByName = (textureName) => {
    return texturePacks.find(x => x.name === textureName).texture;
  }

  const getButterflyMesh = () => {
    const geometry = new THREE.PlaneGeometry(0.8, 1.2);
    const material = new THREE.MeshBasicMaterial({map: getTexureByName('butterfly-wing'), side: THREE.DoubleSide});
    const customMaterial = customAvatarShader(material, globalUniforms);
    const leftWing = new THREE.Mesh(geometry, customMaterial);
    leftWing.position.x = -0.55;
    const lwing = new THREE.Group();
    lwing.add(leftWing);

    const rightWing = new THREE.Mesh(geometry, customMaterial);
    rightWing.rotation.y = Math.PI;
    rightWing.position.x = 0.55;
    const rwing = new THREE.Group();
    rwing.add(rightWing);

    const geometry2 = new THREE.PlaneGeometry(0.8, 1.2);
    const material2 = new THREE.MeshBasicMaterial({map:  getTexureByName('butterfly-body'), side: THREE.DoubleSide});
    const customMaterial2 = customAvatarShader(material2, globalUniforms);
    const body = new THREE.Mesh(geometry2, customMaterial2);

    const group2 = new THREE.Group();
    const group = new THREE.Group();
    const butterflySize = 0.05;
    group.add(body);
    group.add(lwing);
    group.add(rwing);
    group.rotation.y = Math.PI;
    group.rotation.x = Math.PI / 2;
    group2.add(group);
    group2.scale.set(butterflySize, butterflySize, butterflySize);
    return group2;
  }
  const maxButterflyDistance = 50;
  const setButterflyInfo = (butterfly) => {
    butterfly.destination.set(
      player.position.x + (Math.random() - 0.5) * maxButterflyDistance,
      player.position.y + 2 + Math.random() * 2,
      player.position.z + (Math.random() - 0.5) * maxButterflyDistance,
    )
    butterfly.attraction = 0.01 + Math.random() * 0.09;
    butterfly.velocity.set(rnd(1, true), rnd(1, true), rnd(1, true));
  }

  const resetPosition = (butterfly, isDay) => {
    const visible = (isDay && window.gameManagers.reginTrackerManager.region !== 'beach' && window.gameManagers.reginTrackerManager.region !== 'homespace_exterior')
    if (!visible) {
      butterfly.visible = false;
      return;
    } else {
      butterfly.visible = true;
    }
    butterfly.position.set(
      player.position.x + (Math.random() - 0.5) * maxButterflyDistance,
      player.position.y + 2 + Math.random() * 2,
      player.position.z + (Math.random() - 0.5) * maxButterflyDistance,
    )
    setButterflyInfo(butterfly);
  }

  const rnd = (max, negative) => {
    return negative ? Math.random() * 2 * max - max : Math.random() * max;
  }

  const limit = (number, min, max) => {
    return Math.min(Math.max(number, min), max);
  }

  const initializeButterFly = () => {
    const butterflyMesh = getButterflyMesh();
    const butterflies = new THREE.Group();
    const butterflyCount = particleCount;
    for (let i = 0; i < butterflyCount; i ++) {
      const butterfly = butterflyMesh.clone();
      butterfly.destination = new THREE.Vector3();
      butterfly.velocity = new THREE.Vector3();
      setButterflyInfo(butterfly);
      butterfly.position.set(butterfly.destination.x, butterfly.destination.y, butterfly.destination.z);
      butterflies.add(butterfly);
    }
    return butterflies;
  }

  const butterflies = initializeButterFly();
  const localVector = new THREE.Vector3();
  butterflies.update = (timestamp, isDay) => {
    for (const butterfly of butterflies.children) { 
      localVector.set(butterfly.destination.x, butterfly.destination.y, butterfly.destination.z);
      const dv = localVector.sub(butterfly.position).normalize();
      butterfly.velocity.x += butterfly.attraction * dv.x;
      butterfly.velocity.y += butterfly.attraction * dv.y;
      butterfly.velocity.z += butterfly.attraction * dv.z;

      butterfly.velocity.x = limit(butterfly.velocity.x, -0.025, 0.025);
      butterfly.velocity.y = limit(butterfly.velocity.y, -0.025, 0.025);
      butterfly.velocity.z = limit(butterfly.velocity.z, -0.025, 0.025);

      const v2 = butterfly.velocity;
      const wavingSpeed = 0.03 + butterfly.attraction * 0.1;
      butterfly.children[0].children[1].rotation.y = Math.cos(timestamp * wavingSpeed);
      butterfly.children[0].children[2].rotation.y = -Math.cos(timestamp * wavingSpeed);

      butterfly.lookAt(butterfly.position.clone().add(v2));
      butterfly.position.add(v2);

      if (
        (butterfly.position.distanceTo(butterfly.destination) < 1)
      ) {
        resetPosition(butterfly, isDay);
      }
      // if (butterfly.position.distanceTo(butterfly.destination) < 1) {
      //   setButterflyInfo(butterfly);
      // }
    }
  }

  return butterflies;
}