import soundFileSpecs from './sound-files.json';

const _getSoundFiles = regex => soundFileSpecs.filter(f => regex.test(f.name));
const soundFiles = {
  BGDayMusic: _getSoundFiles(/^backgroundMusic\/day/),
  BGNightMusic: _getSoundFiles(/^backgroundMusic\/night/),
  BGDaySound: _getSoundFiles(/^backgroundSound\/bird/),
  BGNightSound: _getSoundFiles(/^backgroundSound\/insect/),
};

async function loadAudioBuffer(audioContext, url) {
  const res = await fetch(url);
  const arrayBuffer = await res.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  return audioBuffer;
}

export class Sounds {
  constructor() {
    this.audioContext = new AudioContext();
    this.audioContext.gain = this.audioContext.createGain();
    this.audioContext.gain.connect(this.audioContext.destination);
    
    this.loadPromise = (async () => {
      this.soundFileAudioBuffer = await loadAudioBuffer(
        this.audioContext,
        '/sounds/sounds.mp3'
      );
    })();
  }

  getSoundFiles() {
    return soundFiles;
  }

  getSoundFileAudioBuffer() {
    return this.soundFileAudioBuffer;
  }

  playSound(audioSpec) {
    const {offset, duration} = audioSpec[0];
    const audioBufferSourceNode = this.audioContext.createBufferSource();
    audioBufferSourceNode.buffer = this.soundFileAudioBuffer;
    
    const gainNode = this.audioContext.createGain();
    gainNode.connect(this.audioContext.gain);

    audioBufferSourceNode.connect(gainNode);
    audioBufferSourceNode.start(0, offset, duration);
    audioBufferSourceNode.volume = gainNode.gain;
    return audioBufferSourceNode;
  }
  
  async waitForLoad() {
    await this.loadPromise;
  }
}