import * as THREE from 'three'


export class WeaponManager {
	constructor(models) {
    this.player = window.gameEngine.useLocalPlayer();
    this.player.wearing = true;

    this.sword = models.weapons.sword.scene.children[0];
    this.sword.scale.set(100, 100, 100);
    this.player.sword = this.sword;
    this.attacking = false;

    this.comboCount = 0;

    this.enableAttack = false;

    this.unwearSword();

    window.hooks.on('animation:finish_attacking', () => {
      this.attacking = false;
      this.comboCount = 0;
      this.enableAttack = true
      setTimeout(() => { // only can do the next combo after 0.5s
        this.enableAttack = false;
      }, 500)
    })

	}

  unwearSword() {
    this.sword.rotation.set(Math.PI / 2, -Math.PI / 3, 0);
    this.sword.position.set(-1200, 3000, -1200);
    this.player.spineBone.add(this.sword)
    this.player.wearing = false;
  }

  wearSword() {
    window.gameManagers.actionsManager.setCurrentAction('swordDraw');
    this.sword.rotation.set(Math.PI / 2, 0, 0);
    this.sword.position.set(0, 0, 0);
    this.player.handBone.add(this.sword)
    this.player.wearing = true;
  }

  handelMouseLeftClick() {
    if (!this.player.wearing) {
      this.wearSword();
    } else {
      if (!this.enableAttack) {
        this.attacking = true;
        this.comboCount ++;
      }
      
    }
  }

  handelMouseRightClick() {
    if (this.player.wearing && !this.attacking) {
      this.unwearSword();
    }
  }

  update(timestamp) {
    if (this.attacking) {
      this.lastLeftClick = timestamp
    }

    // if (timestamp - this.lastLeftClick > 500) {
    //   this.comboCount = 0;
    // }
    
  }
  

  
}