export const customSakuraShader = (material, uniforms) => {
  const customSakuraMaterial = material.clone();
  
  customSakuraMaterial.uniforms = uniforms;
  
  customSakuraMaterial.onBeforeCompile = shader => {
    shader.uniforms.eye = uniforms.eye;
    shader.uniforms.isDay = uniforms.isDay;
    shader.uniforms.sunPosition = uniforms.sunPosition;
    shader.uniforms.noiseTexture = uniforms.noiseTexture;
    shader.uniforms.uTime = uniforms.uTime;

    shader.vertexShader = shader.vertexShader.replace(
      `#include <uv_pars_vertex>`,
      `
      #include <uv_pars_vertex>
      varying vec3 vSurfaceNormal;
      varying vec3 vWorldPosition;
      uniform sampler2D noiseTexture;
      uniform float uTime;
      `,
    );

    shader.vertexShader = shader.vertexShader.replace(
      `void main() {`,
      `
      void main() {
        vSurfaceNormal = normalize(mat3(modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz) * normal);
      `,
    );

    shader.vertexShader = shader.vertexShader.replace(
      `#include <begin_vertex>`,
      `
      vec3 pos = position;

      vec3 windPos = (modelMatrix * vec4(pos, 1.0)).xyz;
      // wind
      float windNoiseUvScale = 0.1;
      float windNoiseUvSpeed = 0.03;
      vec2 windNoiseUv = vec2(
        windPos.x * windNoiseUvScale + uTime * windNoiseUvSpeed,
        windPos.z * windNoiseUvScale + uTime * windNoiseUvSpeed
      );
      float windNoise = texture2D(noiseTexture, windNoiseUv).r - 0.5;
      float windNoiseScale = 2.0;
      pos += sin(windNoise * vec3(windNoiseScale, windNoiseScale, 0.));

      vec3 transformed = vec3( pos );
      
      #ifdef USE_ALPHAHASH

        vPosition = vec3( position );

      #endif
      `,
    );
    
    shader.vertexShader = shader.vertexShader.replace(
      `#include <worldpos_vertex>`,
      `
      #include <worldpos_vertex>
      vWorldPosition = transformed;
      `,
    );

    

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <uv_pars_fragment>`,
      `
      #include <uv_pars_fragment>
      varying vec3 vSurfaceNormal;
      varying vec3 vWorldPosition;

      uniform vec3 sunPosition;
      uniform bool isDay;
      uniform vec3 eye;
      `,
    );

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <map_fragment>`,
      `
      #ifdef USE_MAP
        vec4 sampledDiffuseColor = texture2D( map, vUv );
        #ifdef DECODE_VIDEO_TEXTURE
          // inline sRGB decode (TODO: Remove code when https://crbug.com/1256340 is solved)
          sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.w );
        #endif
        if (sampledDiffuseColor.r < 0.5) {
          discard;
        }
        vec3 flowerColor = mix(vec3(0.750, 0.255, 0.659) * 0.5, vec3(0.940, 0.545, 0.907), sampledDiffuseColor.b);
        diffuseColor *= vec4(flowerColor, 1.0);
      #endif
      `,
    );

    
    
   
    shader.fragmentShader = shader.fragmentShader.replace(
      `vec4 diffuseColor = vec4( diffuse, opacity );`,
      `
        vec3 col = diffuse;

        vec3 lightPos = isDay ? sunPosition : -sunPosition;
        vec3 lightDir = normalize(lightPos);
        
        float lambertTerm = dot(vSurfaceNormal, lightDir);
        float specularPow = 5.0;
        vec3 E = normalize(eye - vWorldPosition);
        vec3 R = reflect(lightDir, vSurfaceNormal);
        float specularTerm = pow(max(dot(R, E), 0.0), specularPow);
        float EDotN = clamp(dot(E, vSurfaceNormal), 0.0, 1.0);

        vec3 ambientColor = vec3(0.1, 0.1, 0.1);
        vec3 lightColor = vec3(1.0);
        float diffuseStrength = 20.0;
        vec3 lightDiffuse = lightColor * vec3(lambertTerm * diffuseStrength);
        float specularMultiplier = 0.5;
        vec3 specular = lightColor * vec3(specularTerm * specularMultiplier);

        

        vec3 finalLight = ambientColor + lightDiffuse + specular;
        finalLight = clamp(finalLight, 0.5, 1.0);
        col *= finalLight;

        float sunShade = dot(vec3(0.0, 1.0, 0.0), - sunPosition);
        sunShade = sunShade * 0.5 + 0.5;
        vec3 shadeColor = col * vec3(0.3, 0.5, 0.7);
        col = mix(col, shadeColor, sunShade);



        vec4 diffuseColor = vec4( col, opacity );
      `,
    );
    // console.log(shader.vertexShader);
    // console.log(shader.fragmentShader);
  };
  return customSakuraMaterial;
}