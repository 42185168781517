import * as THREE from 'three'

import { 
  HOKAGE_POSITION, 
  HOKAGE_RADIUS, 
  HOMESPACE_INTERIOR_POSITION, 
  HOMESPACE_INTERIOR_RADIUS, 
  HOMESPACE_EXTERIOR_POSITION, 
  HOMESPACE_EXTERIOR_RADIUS,
  INTERACT_SPOTS, 
} from './constants';

export class ReginTrackerManager {
  constructor() {
    this.player = window.gameEngine.useLocalPlayer();
    this.region = 'hokage';

    this.lastTrackInteractSpotTime = 0;
    this.currentInteractSpot = null;



    this.focusDesk = false;
    window.hooks.on('cameraFocus:desk', () => { 
      this.focusDesk = !this.focusDesk;

      window.gameManagers.game.handleDeskFocus();
      window.gameManagers.ioManager.handleDeskFocus();

      if (!this.player.avatar.visible) {
        this.player.avatar.visible = true;
      }

      // hack: overwrite the message
      if (!this.focusDesk) {
        window.hooks.emit('interactspot_text', '-Press the E key to access my profile-');
      } else {
        window.hooks.emit('interactspot_text', '-Press the E key to exit my profile-');
      }
    });
    
  }
  distance2(x1, y1, x2, y2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
  
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
  
    return distance;
  }

  distance3(x1, y1, z1, x2, y2, z2) {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const deltaZ = z2 - z1;

    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2 + deltaZ ** 2);

    return distance;
  }


  update(timestamp) {
    if (!window.gameStart) {
      return;
    }
    let currentRegion = '';
    if (this.distance3(this.player.position.x, this.player.position.y, this.player.position.z, HOMESPACE_INTERIOR_POSITION[0], HOMESPACE_INTERIOR_POSITION[1], HOMESPACE_INTERIOR_POSITION[2]) < HOMESPACE_INTERIOR_RADIUS) {
      currentRegion = 'homespace_interior';
    } else if (this.distance2(this.player.position.x, this.player.position.z, HOKAGE_POSITION[0], HOKAGE_POSITION[1]) < HOKAGE_RADIUS) {
      currentRegion = 'homespace_exterior';
    } else {
      if (this.player.position.y <= 1.2) {
        currentRegion = 'beach';
      } else {
        currentRegion = 'grassland';
      }
    }
    if (this.region !== currentRegion) {
      this.region = currentRegion;
      window.hooks.emit('current_region', this.region);
    }

    // track interact spot
    if (timestamp - this.lastTrackInteractSpotTime > 200) {
      this.lastTrackInteractSpotTime = timestamp;
      let interactSpot = null;
      for (const spot of INTERACT_SPOTS) {
        const distance = this.distance3(this.player.position.x, this.player.position.y, this.player.position.z, spot.position[0], spot.position[1], spot.position[2]);
        const radius = spot.radius ? spot.radius : 1;
        if (distance < radius) {
          interactSpot = spot;
          break;
        }
      }
      if (interactSpot) {
        if (this.currentInteractSpot !== interactSpot) {
          this.currentInteractSpot = interactSpot;
          window.hooks.emit('interactspot_text', interactSpot.message);
        }
      } else {
        if (this.currentInteractSpot !== interactSpot) {
          this.currentInteractSpot = null;
          window.hooks.emit('interactspot_text', null);
        }
      }
    }

    

  }
}
