import * as THREE from 'three'
import { AVATAR_HEIGHT } from './constants';
import physicsManager from './physics/physics-manager';

const localVector = new THREE.Vector3();
const localVector2 = new THREE.Vector3();
const localVector3 = new THREE.Vector3();

const localVector4 = new THREE.Vector3();
const localQuaternion = new THREE.Quaternion();
const localQuaternion2 = new THREE.Quaternion();

const _euler = new THREE.Euler(0, 0, 0, 'YXZ');
const cameraOffset = new THREE.Vector3(0, 0, -1.5);
let cameraOffsetTargetZ = cameraOffset.z;

export class CameraManager {
  constructor()  {
    this.player = window.gameEngine.useLocalPlayer();
    this.camera = window.gameEngine.useInternals().camera;
    this.physics = physicsManager.getScene();

    this.targetPosition = new THREE.Vector3(0, 0, 0);
    this.targetPosition.copy(this.player.position)
    this.targetQuaternion = new THREE.Quaternion();

    this.mouse = new THREE.Vector2()
    

    this.loadCameraController(0.2, 0.4);
  }

  limitZoom() {
    if (window.gameManagers.reginTrackerManager.region === 'homespace_interior') {
      cameraOffsetTargetZ = Math.max(cameraOffsetTargetZ, -2.6);
    }
  }

  async loadCameraController(cameraWidth, cameraHeight) {
    this.cameraWidth = cameraWidth;
    this.cameraHeight = cameraHeight;

    this.capsuleWidth = cameraWidth / 2;
    this.capsuleHeight = cameraHeight - cameraWidth;

    const contactOffset = 0.01 * this.capsuleHeight;
    const stepOffset = 0.1 * this.capsuleHeight;

    const position = this.player.position.clone();

    this.cameraController = this.physics.createCharacterController(
      this.capsuleWidth,
      this.capsuleHeight,
      contactOffset,
      stepOffset,
      position
    );
    window.gameEngine.useInternals().scene.add(this.cameraController)
  }

  getCameraOffset() {
    return cameraOffset;
  }

  handleMouseMove(e) {
    const focusDesk = window.gameManagers.reginTrackerManager.focusDesk;
    if (focusDesk) {
      // const canvas = document.querySelector('canvas.webgl');
      // const renderer = window.gameEngine.useInternals().renderer;
      // const rect = renderer.domElement.getBoundingClientRect();
      // const x = e.clientX - rect.left;
      // const y = e.clientY - rect.top;

      // this.mouse.x = ( x / canvas.clientWidth ) *  2 - 1;
      // this.mouse.y = ( y / canvas.clientHeight) * - 2 + 1

      // this.mouse.x = e.clientX / window.innerWidth * 2 - 1;
      // this.mouse.y = - (e.clientY / window.innerHeight) * 2 + 1;
      return;
    }
    const {camera} = window.gameEngine.useInternals();

    const movementX = e.movementX ||
      e.mozMovementX ||
      e.webkitMovementX ||
      0;

    const movementY = e.movementY ||
      e.mozMovementY ||
      e.webkitMovementY ||
      0;
    
    _euler.setFromQuaternion(camera.quaternion);
  
    const maxRotateDegree = 0.002;
    _euler.y -= movementX * maxRotateDegree;
    _euler.x -= movementY * maxRotateDegree;
  
    _euler.x = Math.max(Math.PI / 2 - Math.PI, Math.min( Math.PI / 2, _euler.x ));
  
    camera.quaternion.setFromEuler(_euler);
    this.targetQuaternion.setFromEuler(_euler);
  }

  handleWheelEvent(e) {
    const focusDesk = window.gameManagers.reginTrackerManager.focusDesk;
    if (focusDesk) {
      return;
    }
    cameraOffsetTargetZ = Math.min(cameraOffset.z - e.deltaY * 0.01, 0);
    if (cameraOffsetTargetZ >= -0.2) {
      this.player.avatar.visible = false;
    } else {
      if (!this.player.avatar.visible) {
        this.player.avatar.visible = true;
      }
    }
    
  }

  setCameraOffset(offset) {
    cameraOffsetTargetZ = offset;
    if (cameraOffsetTargetZ >= -0.2) {
      this.player.avatar.visible = false;
    } else {
      if (!this.player.avatar.visible) {
        this.player.avatar.visible = true;
      }
    }
  }

  lerp(start, end, t) {
    return start * (1 - t) + end * t;
  }

  updatePost(timestamp, timeDiff) {
    this.limitZoom();
    const focusDesk = window.gameManagers.reginTrackerManager.focusDesk;
    const {camera} = window.gameEngine.useInternals();
    const localPlayer = window.gameEngine.useLocalPlayer();

    if (focusDesk) {
      _euler.set(-0.6840000000000025, -2.870000000000011, -8.952818491314819e-17)
      camera.position.copy(camera.position.lerp(localVector4.set(-868.0372924804689, 503.0814514160156, -1113.094970703125), 0.1));
      camera.quaternion.copy(camera.quaternion.slerp(localQuaternion.setFromEuler(_euler), 0.1));
      this.targetQuaternion.copy(this.targetQuaternion.slerp(localQuaternion2.setFromEuler(_euler), 0.1));
      return;
    }
    
   
    
    const _lerpCameraOffset = () => {
      const lerpFactor = 0.15;
      let cameraOffsetZ = cameraOffsetTargetZ;
      if (cameraOffsetZ > -0.5) {
        cameraOffsetZ = 0;
      }
      cameraOffset.z = cameraOffset.z * (1 - lerpFactor) + cameraOffsetZ * lerpFactor;
    };
    _lerpCameraOffset();

    
    const avatarCameraOffset = this.getCameraOffset();
    

    localVector3.copy(localPlayer.position)
          .sub(
            localVector.copy(avatarCameraOffset)
              .applyQuaternion(this.targetQuaternion)
          );
    const distanceThreshols = 0.5;
    const cameraDisToPlayer = cameraOffsetTargetZ + this.targetPosition.distanceTo(this.player.position);
    if (window.gameManagers.reginTrackerManager.region === 'homespace_interior' && cameraDisToPlayer < distanceThreshols) {
        localVector2.set(localVector3.x - this.targetPosition.x, localVector3.y - this.targetPosition.y, localVector3.z - this.targetPosition.z);
    
        const flags = this.physics.moveCharacterController(
          this.cameraController,
          localVector2,
          0,
          0,
          this.cameraController.position
        );
    
        this.targetPosition.x = this.lerp(this.cameraController.position.x, this.targetPosition.x, 0.1);
        this.targetPosition.z = this.lerp(this.cameraController.position.z, this.targetPosition.z, 0.1);
        this.targetPosition.y = this.lerp(this.cameraController.position.y, this.targetPosition.y, 0.1);
      
      
    } else {
      this.targetPosition.copy(localVector3)
    }
    

    camera.position.copy(this.targetPosition);

    this.physics.setCharacterControllerPosition(this.cameraController, this.targetPosition)
    
    // TODO fix these
    camera.position.y += AVATAR_HEIGHT;
    const chunks = window.gameManagers.procgenManager.state.chunks;
    const currentCameraGroundHeight = chunks.getElevationForPosition(camera.position.x, camera.position.z);
    if(currentCameraGroundHeight && camera.position.y < currentCameraGroundHeight + 1) {
      camera.position.y = currentCameraGroundHeight + 1;
    }
  }
}