import * as THREE from 'three';
import { sakuraVertexShader, sakuraFragmentShader } from '../Material/sakura-petal/shader';
import { grassBaseColor } from '../../utils/constants';
import { LEFT_SAKURA_POSITION, RIGHT_SAKURA_POSITION } from '../../../constants';


export const getSakura = (particleCount, player, texturePacks, globalUniforms) => {

  const _getGeometry = (geometry, attributeSpecs, particleCount) => {
    const geometry2 = new THREE.BufferGeometry();
    ['position', 'normal', 'uv'].forEach(k => {
    geometry2.setAttribute(k, geometry.attributes[k]);
    });
    geometry2.setIndex(geometry.index);

    const positions = new Float32Array(particleCount * 3);
    const positionsAttribute = new THREE.InstancedBufferAttribute(positions, 3);
    geometry2.setAttribute('positions', positionsAttribute);

    for(const attributeSpec of attributeSpecs){
        const {
            name,
            itemSize,
        } = attributeSpec;
        const array = new Float32Array(particleCount * itemSize);
        geometry2.setAttribute(name, new THREE.InstancedBufferAttribute(array, itemSize));
    }

    return geometry2;
  };

  const getTexureByName = (textureName) => {
    return texturePacks.find(x => x.name === textureName).texture;
  }

  const rnd = (max, negative) => {
    return negative ? Math.random() * 2 * max - max : Math.random() * max;
  }

  const limit = (number, min, max) => {
    return Math.min(Math.max(number, min), max);
  }

  let info = {
    velocity: [particleCount],
    position: [particleCount],
    destination: [particleCount],
    attraction: [particleCount],
    rotDir: [particleCount],
    scale:[particleCount]
  }
  const attributeSpecs = [];
  attributeSpecs.push({name: 'scales', itemSize: 1});
  attributeSpecs.push({name: 'textureRotation', itemSize: 1});
  attributeSpecs.push({name: 'rotationX', itemSize: 1});
  attributeSpecs.push({name: 'rotationY', itemSize: 1});

  const geometry2 = new THREE.PlaneGeometry(0.3, 0.3);
  const geometry = _getGeometry(geometry2, attributeSpecs, particleCount);

  const material = new THREE.ShaderMaterial({
    uniforms: {
      uTime: { value: 0 },
      grassBaseColor:{
        value: grassBaseColor
      },
      leaftexture: {
        value: getTexureByName('sakura')
      },
      noisetexture: {
        value: getTexureByName('noise3')
      },
    },
    vertexShader: sakuraVertexShader,
    fragmentShader: sakuraFragmentShader,
    side: THREE.DoubleSide,
    // transparent: true,
    // depthWrite: false,
    // blending: THREE.AdditiveBlending
  });
  material.uniforms.sunPosition = globalUniforms.sunPosition;

  const resetPositionRadius = 5;
  const resetDistanceRadius = 8;
  const resetDistanceHeight = 7;

  const setSakuraInfo = (index) => {
    const visiblie = window.gameManagers.reginTrackerManager && 
    window.gameManagers.reginTrackerManager.region === 'homespace_exterior';
    const startPosition = Math.random() > 0.5 ? LEFT_SAKURA_POSITION : RIGHT_SAKURA_POSITION;
    info.position[index].set(
      startPosition[0] + (Math.random() - 0.5) * resetPositionRadius,
      5.0 + Math.random() * 3.0,
      startPosition[1] + (Math.random() - 0.5) * resetPositionRadius,
    )
    info.destination[index].set(
      info.position[index].x + (Math.random() - 0.5) * resetDistanceRadius,
      info.position[index].y - Math.random() * resetDistanceHeight,
      info.position[index].z + (Math.random() - 0.5) * resetDistanceRadius
    )
    info.attraction[index] = 0.01 + Math.random() * 0.025;
    info.velocity[index].set(rnd(1, true), rnd(1, true), rnd(1, true));

    if (visiblie) {
      info.scale[index] = 0.3 + Math.random() * 0.7;
    } else {
      info.scale[index] = 0;
    }
    
  }


  const sakura = new THREE.InstancedMesh(geometry, material, particleCount);

  // initialize
  const positionsAttribute = sakura.geometry.getAttribute('positions');
  const rotationXAttribute = sakura.geometry.getAttribute('rotationX');
  const rYAttribute = sakura.geometry.getAttribute('rotationY');
  const scalesAttribute = sakura.geometry.getAttribute('scales');
  for (let i = 0; i < particleCount; i ++) {
    const maxRotationX = Math.PI / 2;
    rotationXAttribute.setX(i, Math.random() * maxRotationX);

    info.position[i] = new THREE.Vector3();
    info.destination[i] = new THREE.Vector3();
    info.velocity[i] = new THREE.Vector3();
    setSakuraInfo(i);

    positionsAttribute.setXYZ(
      i,
      info.position[i].x,
      info.position[i].y,
      info.position[i].z
    );
    
    scalesAttribute.setX(i, 0.3 + Math.random() * 0.7);
    
    rYAttribute.setX(i, Math.random() * 2 * Math.PI);
    info.rotDir[i] = Math.random() > 0.5 ? 1 : -1;
  }
  scalesAttribute.needsUpdate = true;
  rotationXAttribute.needsUpdate = true;
  positionsAttribute.needsUpdate = true;
  rYAttribute.needsUpdate = true; 

  const localVector = new THREE.Vector3();
  const localVector2 = new THREE.Vector3();
  sakura.update = (timestamp) => {
    const textureRotationAttribute = sakura.geometry.getAttribute('textureRotation');
    const positionsAttribute = sakura.geometry.getAttribute('positions');
    const rotationYAttribute = sakura.geometry.getAttribute('rotationY');
    const scalesAttribute = sakura.geometry.getAttribute('scales');

    for (let i = 0; i < particleCount; i ++) {
      localVector.set(info.destination[i].x, info.destination[i].y, info.destination[i].z);
      const dv = localVector.sub(info.position[i]).normalize();

      info.velocity[i].x += info.attraction[i] * dv.x;
      info.velocity[i].y += info.attraction[i] * dv.y;
      info.velocity[i].z += info.attraction[i] * dv.z;

      info.velocity[i].x = limit(info.velocity[i].x, -0.00875, 0.00875);
      info.velocity[i].y = limit(info.velocity[i].y, -0.00875, 0.0);
      info.velocity[i].z = limit(info.velocity[i].z, -0.00875, 0.00875);

      const v2 = info.velocity[i];

      info.position[i].add(v2);

      positionsAttribute.setXYZ(
        i,
        info.position[i].x,
        info.position[i].y,
        info.position[i].z
      )

      scalesAttribute.setX(i, info.scale[i]);

      let currentSpeed = localVector2.set(info.velocity[i].x, 0, info.velocity[i].z).length(); 
      currentSpeed *= 20;
      const rotSpeed = info.rotDir[i] * (1. - currentSpeed) * 0.1;
      rotationYAttribute.setX(i, rotationYAttribute.getX(i) + rotSpeed);
      textureRotationAttribute.setX(i, textureRotationAttribute.getX(i) + currentSpeed * 0.03);
      if (info.position[i].distanceTo(info.destination[i]) < 1) {
        setSakuraInfo(i);
      }
    }

    positionsAttribute.needsUpdate = true;
    textureRotationAttribute.needsUpdate = true;
    rotationYAttribute.needsUpdate = true;
    scalesAttribute.needsUpdate = true;

    material.uniforms.uTime.value = timestamp / 1000;


  }
  return sakura;
}