const sakuraVertexShader = `\   
  uniform float uTime;
  uniform sampler2D noisetexture;

  attribute float scales;
  attribute float textureRotation;
  attribute float rotationX;
  attribute float rotationY;
  attribute vec3 positions;

  varying vec2 vUv;
  varying float vTextureRotation;

  #define PI 3.1415926

  void main() { 
    float noise = texture2D(noisetexture, positions.xz + uTime * 0.1).r;   
    noise = noise - 0.5;
    noise *= PI * 2.;
    float noiseScale = 0.0;
    noise *= noiseScale;
    mat3 rotX = mat3(
      1.0, 0.0, 0.0, 
      0.0, cos(rotationX), sin(rotationX), 
      0.0, -sin(rotationX), cos(rotationX)
    );
    mat3 rotY = mat3(
      cos(mod(rotationY, PI * 2.) + noise), 0.0, -sin(mod(rotationY, PI * 2.) + noise), 
      0.0, 1.0, 0.0, 
      sin(mod(rotationY, PI * 2.) + noise), 0.0, cos(mod(rotationY, PI * 2.) + noise)
    );
    
    vUv = uv;
    vTextureRotation = textureRotation;
    
    vec3 pos = position;
    pos *= scales;
    pos *= rotX;
    pos *= rotY;
    pos += positions;
    
    vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;
    gl_Position = projectionPosition;
  }
`;
const sakuraFragmentShader = `\
  uniform sampler2D leaftexture;
  uniform vec3 grassBaseColor;
  uniform vec3 sunPosition;

  varying vec2 vUv;
  varying float vTextureRotation;

  #define PI 3.1415926

  void main() {
    float mid = 0.5;
    vec2 rotated = vec2(
      cos(mod(vTextureRotation, PI * 2.)) * (vUv.x - mid) - sin(mod(vTextureRotation, PI * 2.)) * (vUv.y - mid) + mid,
      cos(mod(vTextureRotation, PI * 2.)) * (vUv.y - mid) + sin(mod(vTextureRotation, PI * 2.)) * (vUv.x - mid) + mid
    );
    
    vec4 leaf = texture2D(leaftexture, rotated);  
    vec3 col = mix(vec3(0.940, 0.545, 0.907), vec3(0.750, 0.255, 0.659) * 0.5, (1. - leaf.g));
    float sunShade = dot(vec3(0.0, 1.0, 0.0), - sunPosition);
    sunShade = sunShade * 0.5 + 0.5;
    vec3 shadeColor = col * vec3(0.3, 0.5, 0.7);
    col = mix(col, shadeColor, sunShade);
    
    if (leaf.a < 0.5) {
      discard;
    } else {
      gl_FragColor = vec4(col, 1.0);
    }
    
    
  }
`;
export {
  sakuraVertexShader, sakuraFragmentShader,
};