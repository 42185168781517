import * as THREE from 'three'

import View from '../View.js';
import { chunkMinSize, chunkMaxDepth } from '../../utils/constants.js';
import { GRASS_QUALITY_TYPE } from '../../../constants.js';

export default class Grass {
  constructor(instancemanager, terrainState) {
    this.view = View.getInstance();
    this.scene = this.view.instancedScene;

    this.created = false;

    this.instancemanager = instancemanager;
    this.terrainState = terrainState;
    this.terrainState.events.on('ready', () => {
      this.create()
    })
  }

  getGeometry(geometry, positions, slopes) {
    const geometry2 = new THREE.BufferGeometry();
    ['position', 'normal', 'uv'].forEach(k => {
    geometry2.setAttribute(k, geometry.attributes[k]);
    });
    geometry2.setIndex(geometry.index);

    const positionsAttribute = new THREE.InstancedBufferAttribute(positions, 3);
    geometry2.setAttribute('positions', positionsAttribute);

    const slopesAttribute = new THREE.InstancedBufferAttribute(slopes, 3);
    geometry2.setAttribute('slopes', slopesAttribute);

    return geometry2;
  };


  create() {
    const particleCount = this.terrainState.grassPositions.length / 3;
    if (particleCount > 0 && this.terrainState.size === chunkMinSize) {
      const grassLod0Geometry = this.getGeometry(
        this.view.instancesManager.grassLod0Geometry, 
        this.terrainState.grassPositions, 
        this.terrainState.grassTerrainSlopes, 
      );
      this.lod0Mesh = new THREE.InstancedMesh(grassLod0Geometry, this.view.instancesManager.grassMaterial, particleCount);
      this.lod0Mesh.visible = false;
      this.lod0Mesh.userData.fadePosition = this.view.instancesManager.plantFadeOutThreshold;
      this.scene.add(this.lod0Mesh);

      const grassLod1Geometry = this.getGeometry(
        this.view.instancesManager.grassLod1Geometry, 
        this.terrainState.grassPositions, 
        this.terrainState.grassTerrainSlopes, 
      );
      this.lod1Mesh = new THREE.InstancedMesh(grassLod1Geometry, this.view.instancesManager.grassMaterial, particleCount);
      this.lod1Mesh.visible = false;
      this.lod1Mesh.userData.fadePosition = this.view.instancesManager.plantFadeOutThreshold;
      this.scene.add(this.lod1Mesh);


      this.mesh = this.view.grassQuality === GRASS_QUALITY_TYPE.HIGH ? this.lod0Mesh : this.lod1Mesh;
      this.mesh.particleCount = particleCount;
      this.mesh.userData.fadePosition = this.view.instancesManager.plantFadeOutThreshold;
      this.mesh.receiveShadow = this.view.shadowEnabled;
      window.hooks.on('toggle-shadow', (disable) => {
        if (disable) {
          this.mesh.receiveShadow = false;
        } else {
          this.mesh.receiveShadow = true;
        }
      });
      // this.scene.add(this.mesh);
      this.mesh.visible = false;
      this.created = true;
    }
  }

  setFadePosition(fadePosition) {
    if (this.created) {
      this.mesh.userData.fadePosition = fadePosition;
    }
  }
  
  showMesh() {
    if (this.mesh && !this.mesh.visible) {
      this.mesh.visible = true;
    }
  }

  hideMesh() {
    if (this.mesh && this.mesh.visible) {
      this.mesh.visible = false;
    }
  }

  showLod0() {
    this.mesh.material.uniforms.highGrassQuality.value = true;
    if (this.lod0Mesh && !this.lod0Mesh.visible) {
      this.lod0Mesh.visible = true;
    }
    if (this.lod1Mesh && this.lod1Mesh.visible) {
      this.lod1Mesh.visible = false;
    }
  }

  showLod1() {
    this.mesh.material.uniforms.highGrassQuality.value = false;
    if (this.lod0Mesh && this.lod0Mesh.visible) {
      this.lod0Mesh.visible = false;
    }
    if (this.lod1Mesh && !this.lod1Mesh.visible) {
      this.lod1Mesh.visible = true;
    }
  }

  setQuality(quality) {
    if (quality === GRASS_QUALITY_TYPE.HIGH) {
      this.mesh = this.lod0Mesh;
      this.showLod0();
    } else {
      this.mesh = this.lod1Mesh;
      this.showLod1();
    }
  }

  destroy() {
    if (this.created) {
      this.mesh.geometry.dispose();
      this.scene.remove(this.mesh);
    }
  }
}