import "./style.css";
import * as THREE from "three";
import Engine from "./engine";
import { LoadingBar } from "./loadingBar.js";
import { EventEmitter } from "./EventEmitter.js";
import { uiStart } from "./ui.js";
import { CameraManager } from "./camera-manager";
import { IoManager } from "./io-manager.js";
import { GameManger } from "./game.js";
import { ActionsManager } from "./actionManager";
import { SondManager } from "./sound-manager";
import { ReginTrackerManager } from "./region-tracker-manger";
import { WeaponManager } from "./weaponManager.js";
import physicsManager from "./physics/physics-manager";
import Stats from "stats.js";

import { texturePacks } from "./assets";
import { instanceGLBPath } from "./assets";
import { buildingGLBPath } from "./assets";
import { weaponGLBPath } from "./assets";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";

import View from "./procgen/View/View.js";
import State from "./procgen/State/State.js";
import { AVATAR_HEIGHT } from "./constants";

import {
  HOMESPACE_EXTERIOR_POSITION,
  HOMESPACE_INTERIOR_POSITION,
} from "./constants";

import { Sounds } from "./sounds";

const loadingManager = new THREE.LoadingManager();
const loadingBar = new LoadingBar();
// loadingManager.onLoad = () => {
// 	loadingBar.visible = false;
// }
loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
  loadingBar.progress = itemsLoaded / itemsTotal;
};

// debug
// const stats = new Stats();
// stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
// document.body.appendChild(stats.dom);

// canvas
const canvas = document.querySelector("canvas.webgl");

// scene
const scene = new THREE.Scene();

// light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
scene.add(ambientLight);

// camera
const camera = new THREE.PerspectiveCamera(
  60,
  window.innerWidth / window.innerHeight,
  0.1,
  10000
);
scene.add(camera);

// renderer
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.shadowMap.enabled = true;

// sound
const sounds = new Sounds();

// player
const player = new THREE.Group();
scene.add(player);
// player.position.set(-867.9671659690924, 6, -1110.7038528841067);
// option
// x: -843.3977661132812, y: 40.90159606933594, z: -1189.282470703125
// x: 556.4534301757812, y: 80.48231887817383, z: -151.16342163085938
// x: 311.0721740722656, y: 46.13009643554676, z: -74.11446380615234
// x: 545.3932201584848, y: 1.9971074261611221, z: -469.9601006595575

const loadAvatar = () => {
  return new Promise((resolve, reject) => {
    const fbxfLoader = new FBXLoader();
    fbxfLoader.load(
      "./assets/character/kakashi_young8.fbx",
      (object) => {
        object.traverse((o) => {
          if (o.isMesh) {
            const avatarTexture = o.material.map;
            o.material = new THREE.MeshBasicMaterial({
              map: avatarTexture,
              side: THREE.DoubleSide,
            });
            o.castShadow = !isMobile;
            window.hooks.on('toggle-shadow', (disable) => {
              if (disable) {
                o.castShadow = false;
              } else {
                o.castShadow = true;
              }
            });
          }
          if (o.isSkinnedMesh) {
            const spineBone = o.skeleton.bones.find(
              (bone) => bone.name === "mixamorigSpine"
            );
            const handBone = o.skeleton.bones.find(
              (bone) => bone.name === "mixamorigRightHandPinky1"
            );
            player.handBone = handBone;
            player.spineBone = spineBone;
          }
        });
        object.scale.set(0.000125, 0.000125, 0.000125);
        // object.position.y = object.position.y - AVATAR_HEIGHT;

        object.rotation.y = Math.PI;
        player.add(object);
        player.avatar = object;
        player.avatar.visible = false;

        resolve(object);
      },
      (xhr) => {},
      (error) => {
        console.log(error);
        reject(error); // Reject the promise if there's an error
      }
    );
  });
};

const loadBuilding = async (models, physics) => {
  // homespace exterior
  const homespaceExterior =
    models.buildingModels.homespaceExterior.scene.children[1];
  homespaceExterior.traverse((o) => {
    if (o.isMesh) {
      // console.log(o)
      o.castShadow = !isMobile;
      window.hooks.on('toggle-shadow', (disable) => {
        if (disable) {
          o.castShadow = false;
        } else {
          o.castShadow = true;
        }
      });
      const texture = o.material.map;
      o.material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
      });
    }
  });

  homespaceExterior.position.set(
    HOMESPACE_EXTERIOR_POSITION[0],
    HOMESPACE_EXTERIOR_POSITION[1],
    HOMESPACE_EXTERIOR_POSITION[2]
  );
  homespaceExterior.scale.set(0.06, 0.06, 0.06);
  homespaceExterior.rotation.z = 0.45;
  // scene.add(homespaceExterior)
  player.homespaceExterior = homespaceExterior;

  // homespace exterior physic
  const homespaceExteriorPhysic =
    models.buildingModels.homespaceExteriorPhysic.scene.children[1];
  homespaceExteriorPhysic.traverse((o) => {
    if (o.isMesh) {
      const texture = o.material.map;
      o.material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.6,
      });
    }
  });
  homespaceExteriorPhysic.position.set(
    HOMESPACE_EXTERIOR_POSITION[0],
    HOMESPACE_EXTERIOR_POSITION[1] - 0.5,
    HOMESPACE_EXTERIOR_POSITION[2]
  );
  homespaceExteriorPhysic.scale.set(0.06, 0.06, 0.06);
  homespaceExteriorPhysic.rotation.z = 0.45;
  // scene.add(homespaceExteriorPhysic)
  const geometryBufferExterior = await physics.cookGeometryAsync(
    homespaceExteriorPhysic
  );
  // console.log(geometryBufferExterior)
  let homespaceExteriorPhysicsId = null;
  if (geometryBufferExterior && geometryBufferExterior.length !== 0) {
    const p = new THREE.Vector3();
    p.set(0, 0, 0);
    const s = new THREE.Vector3();
    s.set(1, 1, 1);
    const q = new THREE.Quaternion();
    q.set(0, 0, 0, 1);
    homespaceExteriorPhysicsId = physics.addCookedGeometry(
      geometryBufferExterior,
      p,
      q,
      s
    );
  }
  physics.disableGeometryQueries(homespaceExteriorPhysicsId);

  // homespace interior
  const homespaceInterior =
    models.buildingModels.homespaceInterior.scene.children[0];
  homespaceInterior.traverse((o) => {
    if (o.isMesh) {
      o.receiveShadow = !isMobile;
      window.hooks.on('toggle-shadow', (disable) => {
        if (o) {
          if (disable) {
            o.receiveShadow = false;
          } else {
            o.receiveShadow = true;
          }
        }
        
      });
      const texture = o.material.map;
      if (!texture) {
        o = null;
      } else {
        o.material = new THREE.MeshStandardMaterial({
          map: texture,
          side: THREE.DoubleSide,
          roughness: 1.0
        });
      }
    }
  });

  homespaceInterior.position.set(
    HOMESPACE_INTERIOR_POSITION[0],
    HOMESPACE_INTERIOR_POSITION[1],
    HOMESPACE_INTERIOR_POSITION[2]
  );
  homespaceInterior.scale.set(0.8, 0.8, 0.8);
  homespaceInterior.rotation.z = 3.14;
  scene.add(homespaceInterior);
  player.homespaceInterior = homespaceInterior;

  // homespace interior physic
  const homespaceInteriorPhysic =
    models.buildingModels.homespaceInteriorPhysic.scene.children[0];
  homespaceInteriorPhysic.position.set(
    -867.9571659690924,
    HOMESPACE_INTERIOR_POSITION[1] + 0.1399999999999,
    -1113.9438528841038
  );
  homespaceInteriorPhysic.scale.set(80, 80, 80);
  homespaceInteriorPhysic.rotation.y = 3.14;
  // scene.add(homespaceInteriorPhysic)
  const geometryBufferInterior = await physics.cookGeometryAsync(
    homespaceInteriorPhysic
  );
  // console.log(geometryBufferInterior)
  let homespaceInteriorPhysicsId = null;
  if (geometryBufferInterior && geometryBufferInterior.length !== 0) {
    const p = new THREE.Vector3();
    p.set(0, 0, 0);
    const s = new THREE.Vector3();
    s.set(1, 1, 1);
    const q = new THREE.Quaternion();
    q.set(0, 0, 0, 1);
    homespaceInteriorPhysicsId = physics.addCookedGeometry(
      geometryBufferInterior,
      p,
      q,
      s
    );
  }

  player.position.set(
    -867.8525390625001,
    502.1277770996094,
    -1109.5753173828125
  );

  window.hooks.on("teleport:leave_homespace", () => {
    scene.add(homespaceExterior);
    physics.enableGeometryQueries(homespaceExteriorPhysicsId);

    scene.remove(homespaceInterior);
    physics.disableGeometryQueries(homespaceInteriorPhysicsId);
  });

  window.hooks.on("teleport:enter_homespace", () => {
    scene.add(homespaceInterior);
    physics.enableGeometryQueries(homespaceInteriorPhysicsId);

    scene.remove(homespaceExterior);
    physics.disableGeometryQueries(homespaceExteriorPhysicsId);
    //TODO: disable exterior
  });

  // 	window.addEventListener('keydown', function(event) {
  // 		// Check if the pressed key is 'x' or 'z'
  // 		if (event.key === '7') {
  // 			homespaceInteriorPhysic.position.x += 0.01;
  // 			console.log(homespaceInteriorPhysic.position)
  // 		} else if (event.key === '4') {
  // 			homespaceInteriorPhysic.position.z += 0.01;
  // 			console.log(homespaceInteriorPhysic.position)
  // 		} else if (event.key === '1') {
  // 			homespaceInteriorPhysic.position.y += 0.01;
  // 			console.log(homespaceInteriorPhysic.position)
  // 		}
  // 		if (event.key === '8') {
  // 			homespaceInteriorPhysic.position.x -= 0.01;
  // 			console.log(homespaceInteriorPhysic.position)
  // 		} else if (event.key === '5') {
  // 			homespaceInteriorPhysic.position.z -= 0.01;
  // 			console.log(homespaceInteriorPhysic.position)
  // 		} else if (event.key === '2') {
  // 			homespaceInteriorPhysic.position.y -= 0.01;
  // 			console.log(homespaceInteriorPhysic.position)
  // 		}

  // 		if (event.key === '+') {
  // 			homespaceInteriorPhysic.rotation.z += 0.03;
  // 			console.log(homespaceInteriorPhysic.rotation)
  // 		} else if (event.key === '-') {
  // 			homespaceInteriorPhysic.rotation.z -= 0.03;
  // 			console.log(homespaceInteriorPhysic.rotation)
  // 		}

  // 		if (event.key === '=') {
  // 			homespaceInteriorPhysic.position.copy(player.position)
  // 			console.log(homespaceInteriorPhysic.rotation)
  // 		}

  // 		if (event.key === '*') {
  // 			homespaceInteriorPhysic.scale.x += 0.01
  // 			homespaceInteriorPhysic.scale.y += 0.01
  // 			homespaceInteriorPhysic.scale.z += 0.01
  // 			console.log(homespaceInteriorPhysic.scale)
  // 		}
  // 		if (event.key === '/') {
  // 			homespaceInteriorPhysic.scale.x -= 0.01
  // 			homespaceInteriorPhysic.scale.y -= 0.01
  // 			homespaceInteriorPhysic.scale.z -= 0.01
  // 			console.log(homespaceInteriorPhysic.scale)
  // 		}

  // });
};

//###################################################### load texture #################################################################
const textureLoader = new THREE.TextureLoader(loadingManager);
const texturesPromise = (async () => {
  for (const texturePack of texturePacks) {
    const texture = textureLoader.load(
      `./textures/${texturePack.name}.${texturePack.ext}`
    );
    if (texturePack.repeat) {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    }
    texturePack.texture = texture;
  }
})();
const waitForTextures = () => texturesPromise;
//###################################################### end of load texture #################################################################

//###################################################### load model #################################################################
const _loadModel = (u) =>
  new Promise((accept, reject) => {
    const gltfloader = new GLTFLoader(loadingManager);
    gltfloader.load(
      u.value,
      (o) => {
        accept(o);
      },
      function onProgress() {},
      reject
    );
  });
const mapObjectToArray = (obj) => {
  const res = [];
  for (const key in obj) res.push({ key: key, value: obj[key] });
  return res;
};
const instanceGLBArray = mapObjectToArray(instanceGLBPath);
const models = {};
const weaponGLBArray = mapObjectToArray(weaponGLBPath);
Promise.all(weaponGLBArray.map(_loadModel)).then(function (arr) {
  const obj = {};
  for (let i = 0; i < weaponGLBArray.length; i++) {
    obj[weaponGLBArray[i].key] = arr[i];
  }
  models["weapons"] = obj;
  return obj;
});
Promise.all(instanceGLBArray.map(_loadModel)).then(function (arr) {
  const obj = {};
  for (let i = 0; i < instanceGLBArray.length; i++) {
    obj[instanceGLBArray[i].key] = arr[i];
  }
  models["instanceModels"] = obj;
  return obj;
});
const buildingGLBArray = mapObjectToArray(buildingGLBPath);
Promise.all(buildingGLBArray.map(_loadModel)).then(function (arr) {
  const obj = {};
  for (let i = 0; i < buildingGLBArray.length; i++) {
    obj[buildingGLBArray[i].key] = arr[i];
  }
  models["buildingModels"] = obj;
  setUpProcgen();
  return obj;
});

//###################################################### end of load model #################################################################

//###################################################### setUp procgen #################################################################
const onBeforeRenders = [];
const instancedScene = new THREE.Group();

const setUpProcgen = async () => {
  await sounds.waitForLoad();
  await waitForTextures();
  const animations = await loadAvatar();

  await physicsManager.waitForLoad();
  window.gameEngine = new Engine(
    player,
    camera,
    renderer,
    scene,
    texturePacks,
    models,
    onBeforeRenders,
    instancedScene
  );

  await loadBuilding(models, physicsManager.getScene());
  loadingBar.visible = false;
  window.hooks.emit("app_loaded");

  const state = new State();
  const view = new View();

  window.gameManagers = {
    cameraManager: new CameraManager(),
    game: new GameManger(),
    ioManager: new IoManager(),
    procgenManager: {
      state: state,
      view: view,
    },
    actionsManager: new ActionsManager(animations),
    soundManager: new SondManager(sounds),
    reginTrackerManager: new ReginTrackerManager(),
    weaponManager: new WeaponManager(models),
  };

  // strat render
  const clock = new THREE.Clock();
  let timestamp = 0;
  let prevTimestamp = 0;

  // view.update(1000); // pre-update terrain so that the tarrain would be loaded
  // state.update(1000); // pre-update terrain so that the tarrain would be loaded

  // setInterval(() => {
  //   timestamp = clock.getElapsedTime() * 1000;
  //   const timeDiff = timestamp - prevTimestamp;
  //   const timeDiffCapped = Math.min(Math.max(timeDiff, 0), 100);

  //   window.gameManagers.cameraManager.updatePost(timestamp);
  //   window.gameManagers.game.update(timestamp, timeDiffCapped);
  //   window.gameManagers.actionsManager.update(timeDiff);
  //   window.gameManagers.soundManager.update(timeDiff);
  //   window.gameManagers.reginTrackerManager.update(timestamp);
  //   // const physicsScene = physicsManager.getScene();
  //   // physicsScene.simulatePhysics(timeDiffCapped);
  //   window.gameManagers.weaponManager.update(timestamp);

  //   if (
  //     timestamp < 1000 ||
  //     window.gameManagers.reginTrackerManager.region !== "homespace_interior"
  //   ) {
  //     // pre-update terrain so that the tarrain would be loaded
  //     view.update(timestamp);
  //     state.update(timestamp);
  //   }

  //   for (const onBeforeRender of onBeforeRenders) {
  //     window.gameManagers.reginTrackerManager.region !== "homespace_interior" &&
  //       onBeforeRender();
  //   }

  //   renderer.render(scene, camera);
  //   prevTimestamp = timestamp;
  // }, 1000 / 60);

  const update = () => {
		// stats.begin();
		timestamp = clock.getElapsedTime() * 1000;
		const timeDiff = timestamp - prevTimestamp;
		const timeDiffCapped = Math.min(Math.max(timeDiff, 0), 100);

    const frameRate = 1000 / 60;
    const bias = 8;
    if (timeDiff >= frameRate - bias) {
      window.gameManagers.cameraManager.updatePost(timestamp);
      window.gameManagers.game.update(timestamp, timeDiffCapped);
      window.gameManagers.actionsManager.update(timeDiff);
      window.gameManagers.soundManager.update(timeDiff);
      window.gameManagers.reginTrackerManager.update(timestamp);
      // const physicsScene = physicsManager.getScene();
      // physicsScene.simulatePhysics(timeDiffCapped);
      window.gameManagers.weaponManager.update(timestamp);
      window.gameManagers.ioManager.update(timestamp);
  
      if (timestamp < 1000 || window.gameManagers.reginTrackerManager.region !== 'homespace_interior') { // pre-update terrain so that the tarrain would be loaded
        view.update(timestamp);
        state.update(timestamp);
      }
  
      for (const onBeforeRender of onBeforeRenders) {
        window.gameManagers.reginTrackerManager.region !== 'homespace_interior' && onBeforeRender();
      }
  
      renderer.render(scene, camera);
      prevTimestamp = timestamp;
    }
		
		window.requestAnimationFrame(update);
		// stats.end();
	}

	update();
};
