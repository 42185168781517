const BG_SOUND_FADE_SPEED = 0.005;

class Tracker {
  constructor(sounds, soundSpec, maxVolume = 1) {
    this.sounds = sounds;
    this.sound = this.sounds.playSound(soundSpec);
    this.fadeOut = false;
    this.end = false;
    this.maxVolume = maxVolume;

    this.sound.volume.value = 0;

    const endedListener = () => {
      this.sound.stop();
      this.sound.removeEventListener('ended', endedListener);
      // console.log('remove remove')
      this.end = true;
    };

    this.sound.addEventListener('ended', endedListener);
  }
  removeSound() {
    this.fadeOut = true;
  }
  update() {
    if (this.fadeOut) {
      if (this.sound.volume.value > 0) {
        this.sound.volume.value -= BG_SOUND_FADE_SPEED * this.maxVolume;
      } else {
        this.end = true;
      }
    } else {
      if (this.sound.volume.value < this.maxVolume) {
        this.sound.volume.value += BG_SOUND_FADE_SPEED * this.maxVolume;
      }
    }
  }
}

export class SondManager {
  constructor(sounds) {
    this.sounds = sounds;

    this.soundFadeOut = false;
    this.musicFadeOut = false;
    
    window.hooks.on('toggle-volume', (mute) => {
      if (mute) {
        this.sounds.audioContext.gain.gain.value = 0;
      } else {
        this.sounds.audioContext.gain.gain.value = 1;
      }
    });
  }

  update() {
    const soundFiles = this.sounds.getSoundFiles();
    if (!window.gameStart) {
      return;
    }

    const isDay = window.gameManagers.procgenManager.view.skyManager.isDay;

    // handle bg fadeIn fadeOut
    if (this.lastIsDay !== isDay) {
      this.currentBGMusic && this.currentBGMusic.removeSound();
      this.currentBGSound && this.currentBGSound.removeSound();
    }

    if (!this.currentBGMusic || this.currentBGMusic.end) {
      const musicFile = isDay ? soundFiles.BGDayMusic : soundFiles.BGNightMusic;
      this.currentBGMusic = new Tracker(this.sounds, musicFile, 0.3);
    }

    if (this.currentBGSound && window.gameManagers.reginTrackerManager.region === 'homespace_interior') {
      this.currentBGSound.removeSound();
    }

    if (window.gameManagers.reginTrackerManager.region !== 'homespace_interior' && (!this.currentBGSound || this.currentBGSound.end)) {
      const soundFile = isDay ? soundFiles.BGDaySound : soundFiles.BGNightSound;
      this.currentBGSound = new Tracker(this.sounds, soundFile);
    }

    this.currentBGMusic && this.currentBGMusic.update();
    this.currentBGSound && this.currentBGSound.update();


    this.lastIsDay = isDay;
  }


}