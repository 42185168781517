import Terrains from './Terrains.js'
// import Sky from './Sky.js';
import SkyManager from './managers/sky-manager.js';
import WaterManager from './managers/water-manager.js';
import InstancesManager from './managers/instances-manager.js';
import physicsManager from '../../physics/physics-manager.js';
import AvatarManager from './managers/avatar-manager.js';
import EnvironmentManager from './managers/environment-manager.js';
import HomespaceManager from './managers/homespace-manager.js';
import { GRASS_QUALITY_TYPE } from '../../constants.js';

export default class View
{
  static instance

  static getInstance()
  {
    return View.instance
  }

  constructor()
  {
    if(View.instance)
      return View.instance

    View.instance = this

    

    this.player = window.gameEngine.useLocalPlayer();
    this.scene = window.gameEngine.useInternals().scene;
    this.camera = window.gameEngine.useInternals().camera;
    this.renderer = window.gameEngine.useInternals().renderer;
    this.texturePacks = window.gameEngine.useTexturePacks();
    this.models = window.gameEngine.useModlePacks();
    this.onBeforeRenders = window.gameEngine.useOnBeforeRenders();
    this.physics = physicsManager.getScene();
    this.instancedScene = window.gameEngine.useInstancedScene(); // the scene contain the instanced mesh that are not render in water reflecotr

    // this.sky = new Sky();
    this.skyManager = new SkyManager();
    this.terrains = new Terrains();
    this.waterManager = new WaterManager();
    this.instancesManager = new InstancesManager();
    this.avatarManager = new AvatarManager();
    this.homespaceManager = new HomespaceManager();
    this.environmentManager = new EnvironmentManager();

    this.shadowEnabled = !isMobile;
    window.hooks.on('toggle-shadow', (disable) => {
      if (disable) {
        this.shadowEnabled = false;
      } else {
        this.shadowEnabled = true;
      }
    });

    this.waterEffectEnabled = true;
    window.hooks.on('toggle-water', (disable) => {
      if (disable) {
        this.waterEffectEnabled = false;
      } else {
        this.waterEffectEnabled = true;
      }
    });

    this.grassQuality = GRASS_QUALITY_TYPE.HIGH;
    window.hooks.on('grass-quality', (quality) => {
      this.grassQuality = quality;
    });
  }
  
  update(timestamp)
  {
    this.terrains.update(timestamp);
    // this.sky.update(timestamp);
    this.skyManager.update(timestamp);
    this.waterManager.update(timestamp);
    this.instancesManager.update(timestamp);
    this.avatarManager.update();
    this.homespaceManager.update(timestamp);
    this.environmentManager.update(timestamp);
  }

  destroy()
  {
  }
}