import * as THREE from 'three'

import View from './View.js'
import State from '../State/State.js';

export default class Terrain {
  constructor(terrains, terrainState, positionVector, scaleVector, quaternionVector) {
    this.state = State.getInstance();
    this.view = View.getInstance();
    this.scene = this.view.scene;
    this.physics = this.view.physics;

    this.positionVector = positionVector;
    this.scaleVector = scaleVector;
    this.quaternionVector = quaternionVector;

    this.terrains = terrains;
    this.terrainState = terrainState;
    this.terrainState.renderInstance = this;
    this.terrainState.events.on('ready', () => {
      this.create()
    })
  }

  create() {
    const terrainsState = this.state.terrains;
    this.geometry = new THREE.BufferGeometry();
    this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(this.terrainState.positions, 3));
    this.geometry.setAttribute('uv', new THREE.Float32BufferAttribute(this.terrainState.uv, 2));

    const biomeWeightArrayNewSize = (this.terrainState.positions.length / 3) * 4;
    const biomeWeightArray = new Float32Array(biomeWeightArrayNewSize);
    biomeWeightArray.fill(0);
    biomeWeightArray.set(this.terrainState.biomeWeight);
    this.geometry.setAttribute('weight', new THREE.Float32BufferAttribute(biomeWeightArray, 4));

    this.geometry.index = new THREE.BufferAttribute(this.terrainState.indices, 1, false);

    if (isMobile) { // the DataTexture does not work on mobile
      this.geometry.setAttribute('normal', new THREE.Float32BufferAttribute(this.terrainState.normals, 3));
    }
    this.texture = new THREE.DataTexture(
      this.terrainState.texture,
      terrainsState.segments,
      terrainsState.segments,
      THREE.RGBAFormat,
      THREE.FloatType,
      THREE.UVMapping,
      THREE.ClampToEdgeWrapping,
      THREE.ClampToEdgeWrapping,
      THREE.LinearFilter,
      THREE.LinearFilter
    );
    this.texture.flipY = false;
    this.texture.needsUpdate = true;

    // Create mesh
    this.mesh = new THREE.Mesh(this.geometry, this.terrains.material);
    this.mesh.receiveShadow = this.view.shadowEnabled;
    window.hooks.on('toggle-shadow', (disable) => {
      if (disable) {
        this.mesh.receiveShadow = false;
      } else {
        this.mesh.receiveShadow = true;
      }
    });
    // this.mesh.castShadow = true;
    
    this.mesh.userData.texture = this.texture

    // this.mesh = new THREE.Mesh(this.geometry, new THREE.MeshBasicMaterial( { color: 0xffff00 } ))
    this.scene.add(this.mesh)


    const _handlePhysics = async () => {
      const geometryBuffer = await this.physics.cookGeometryAsync(
        this.mesh,
      );
      if (geometryBuffer && geometryBuffer.length !== 0) {
        this.physicsId = this.physics.addCookedGeometry(
          geometryBuffer,
          this.positionVector,
          this.quaternionVector,
          this.scaleVector
        );
      }
    }
    _handlePhysics();
    
    this.created = true
  }

  destroy() {
    if(this.created) {
      this.geometry.dispose();
      this.scene.remove(this.mesh);
      this.physicsId && this.physics.removeGeometry(this.physicsId);
    }
  }
}