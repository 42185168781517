import * as THREE from "three";

const canvas = document.querySelector("canvas.webgl");

export class IoManager {
  constructor() {
    this.player = window.gameEngine.useLocalPlayer();
    this.camera = window.gameEngine.useInternals().camera;
    this.renderer = window.gameEngine.useInternals().renderer;

    this.keysDirection = new THREE.Vector3();

    this.keys = {
      up: false,
      down: false,
      left: false,
      right: false,
      shift: false,
      space: false,
      interact: false,
    };
    this.settingsPanelOpen = false;
  }

  handleDeskFocus() {
    const focusDesk = window.gameManagers.reginTrackerManager.focusDesk;
    if (focusDesk) {
      document && document.exitPointerLock && document.exitPointerLock();
    } else {
      canvas && canvas.requestPointerLock && canvas.requestPointerLock();
    }
  }

  setUpListner() {
    const self = this;
    window.addEventListener("keydown", (e) => {
      switch (e.keyCode) {
        case 87: {
          // W
          this.keys.up = true;
          break;
        }
        case 65: {
          // A
          this.keys.left = true;
          break;
        }
        case 83: {
          // S
          this.keys.down = true;
          break;
        }
        case 68: {
          // D
          this.keys.right = true;
          break;
        }
        case 16: {
          // shift
          this.keys.shift = true;
          break;
        }
        case 32: {
          // space
          this.keys.space = true;
          break;
        }

        case 69: {
          // E
          this.keys.interact = true;
          break;
        }
      }
    });
    window.addEventListener("keyup", (e) => {
      switch (e.keyCode) {
        case 87: {
          // W
          this.keys.up = false;
          break;
        }
        case 65: {
          // A
          this.keys.left = false;
          break;
        }
        case 83: {
          // S
          this.keys.down = false;
          break;
        }
        case 68: {
          // D
          this.keys.right = false;
          break;
        }
        case 16: {
          // shift
          this.keys.shift = false;
          break;
        }
        case 32: {
          // space
          this.jumpLock = false; // prevent user hold the space
          this.keys.space = false;
          break;
        }

        case 69: {
          // E
          this.keys.interact = false;
          break;
        }
      }
    });

    window.addEventListener("mousemove", (e) => {
      if (this.locked) {
        window.gameManagers.cameraManager.handleMouseMove(e);
      }
    });

    window.addEventListener("wheel", (e) => {
      window.gameManagers.cameraManager.handleWheelEvent(e);
      // console.log(this.player.position)
    });

    window.addEventListener("open-settings-panel", (e) => {
      self.settingsPanelOpen = true;
    });

    window.addEventListener("close-settings-panel", (e) => {
      self.settingsPanelOpen = false;
    });

    window.addEventListener("click", function (event) {
      const focusDesk = window.gameManagers.reginTrackerManager.focusDesk;
      if (!focusDesk && !self.settingsPanelOpen) {
        canvas && canvas.requestPointerLock && canvas.requestPointerLock();
        if (document.pointerLockElement === canvas) {
          if (event.button === 0) {
            window.gameManagers.weaponManager.handelMouseLeftClick();
          } else if (event.button === 2) {
            window.gameManagers.weaponManager.handelMouseRightClick();
          }
        }
      }
    });

    window.addEventListener("resize", () => {
      // Update camera
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      // Update renderer
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    const lockChangeAlert = () => {
      if (
        document.pointerLockElement === canvas ||
        document.mozPointerLockElement === canvas
      ) {
        this.locked = true;
      } else {
        this.locked = false;
      }
    };
    if ("onpointerlockchange" in document) {
      document.addEventListener("pointerlockchange", lockChangeAlert, false);
    } else if ("onmozpointerlockchange" in document) {
      document.addEventListener("mozpointerlockchange", lockChangeAlert, false);
    }
  }

  getDirectionOffset() {
    if (isMobile) {
      const angle = this.touch.joystick.angle.value;

      function convertAngleRadians(angle) {
        let newAngle = angle - Math.PI / 2; // Subtract π/2 radians
        if (newAngle > Math.PI) {
          newAngle -= 2 * Math.PI; // Normalize the angle if beyond π
        } else if (newAngle < -Math.PI) {
          newAngle += 2 * Math.PI; // Normalize the angle if below -π
        }
        return newAngle;
      }

      return convertAngleRadians(angle);
    } else {
      let directionOffset = 0; // w

      if (this.keys.up) {
        if (this.keys.left) {
          directionOffset = Math.PI / 4; // w+a
        } else if (this.keys.right) {
          directionOffset = -Math.PI / 4; // w+d
        }
      } else if (this.keys.down) {
        if (this.keys.left) {
          directionOffset = Math.PI / 4 + Math.PI / 2; // s+a
        } else if (this.keys.right) {
          directionOffset = -Math.PI / 4 - Math.PI / 2; // s+d
        } else {
          directionOffset = Math.PI; // s
        }
      } else if (this.keys.left) {
        directionOffset = Math.PI / 2; // a
      } else if (this.keys.right) {
        directionOffset = -Math.PI / 2; // d
      }
      return directionOffset;
    }
  }

  setTouch() {
    // camera control
    let touchStartX = 0;
    let touchStartY = 0;
    window.addEventListener("touchmove", (e) => {
      if (this.touch.joystick.active || this.settingsPanelOpen) {
        return;
      }
      const touch = e.touches[0];

      const touchEndX = touch.clientX;
      const touchEndY = touch.clientY;

      const movementX = touchEndX - touchStartX || 0;
      const movementY = touchEndY - touchStartY || 0;

      const touchEvent = {
        movementX,
        movementY,
      };
      touchStartX = touchEndX;
      touchStartY = touchEndY;

      window.gameManagers.cameraManager.handleMouseMove(touchEvent);
    });

    window.addEventListener("touchstart", (e) => {
      if (this.touch.joystick.active || this.settingsPanelOpen) {
        return;
      }
      const touch = e.touches[0];
      touchStartX = touch.clientX;
      touchStartY = touch.clientY;
    });

    this.touch = {};

    /**
     * Joystick
     */
    this.touch.joystick = {};
    this.touch.joystick.active = false;

    // Element
    this.touch.joystick.$element = document.createElement("div");
    this.touch.joystick.$element.style.userSelect = "none";
    this.touch.joystick.$element.style.position = "fixed";
    this.touch.joystick.$element.style.bottom = "10px";
    this.touch.joystick.$element.style.left = "10px";
    this.touch.joystick.$element.style.width = "170px";
    this.touch.joystick.$element.style.height = "170px";
    this.touch.joystick.$element.style.borderRadius = "50%";
    this.touch.joystick.$element.style.transition = "opacity 0.3s 0.0s";
    this.touch.joystick.$element.style.willChange = "opacity";
    // this.touch.joystick.$element.style.opacity = "0";
    // this.touch.joystick.$element.style.backgroundColor = '#ff0000'
    document.body.appendChild(this.touch.joystick.$element);

    this.touch.joystick.$cursor = document.createElement("div");
    this.touch.joystick.$cursor.style.position = "absolute";
    this.touch.joystick.$cursor.style.top = "calc(50% - 30px)";
    this.touch.joystick.$cursor.style.left = "calc(50% - 30px)";
    this.touch.joystick.$cursor.style.width = "60px";
    this.touch.joystick.$cursor.style.height = "60px";
    this.touch.joystick.$cursor.style.border = "2px solid #ffffff";
    this.touch.joystick.$cursor.style.borderRadius = "50%";
    this.touch.joystick.$cursor.style.boxSizing = "border-box";
    this.touch.joystick.$cursor.style.pointerEvents = "none";
    this.touch.joystick.$cursor.style.willChange = "transform";
    this.touch.joystick.$element.appendChild(this.touch.joystick.$cursor);

    this.touch.joystick.$limit = document.createElement("div");
    this.touch.joystick.$limit.style.position = "absolute";
    this.touch.joystick.$limit.style.top = "calc(50% - 75px)";
    this.touch.joystick.$limit.style.left = "calc(50% - 75px)";
    this.touch.joystick.$limit.style.width = "150px";
    this.touch.joystick.$limit.style.height = "150px";
    this.touch.joystick.$limit.style.border = "2px solid #ffffff";
    this.touch.joystick.$limit.style.borderRadius = "50%";
    this.touch.joystick.$limit.style.opacity = "0.25";
    this.touch.joystick.$limit.style.pointerEvents = "none";
    this.touch.joystick.$limit.style.boxSizing = "border-box";
    this.touch.joystick.$element.appendChild(this.touch.joystick.$limit);

    // Angle
    this.touch.joystick.angle = {};

    this.touch.joystick.angle.offset = 0;

    this.touch.joystick.angle.center = {};
    this.touch.joystick.angle.center.x = 0;
    this.touch.joystick.angle.center.y = 0;

    this.touch.joystick.angle.current = {};
    this.touch.joystick.angle.current.x = 0;
    this.touch.joystick.angle.current.y = 0;

    this.touch.joystick.angle.originalValue = 0;
    this.touch.joystick.angle.value = -Math.PI * 0.5;

    // Resize
    this.touch.joystick.resize = () => {
      const boundings = this.touch.joystick.$element.getBoundingClientRect();

      this.touch.joystick.angle.center.x =
        boundings.left + boundings.width * 0.5;
      this.touch.joystick.angle.center.y =
        boundings.top + boundings.height * 0.5;
    };

    window.addEventListener("resize", this.touch.joystick.resize);
    this.touch.joystick.resize();

    // Events
    this.touch.joystick.events = {};
    this.touch.joystick.touchIdentifier = null;
    this.touch.joystick.events.touchstart = (_event) => {
      _event.preventDefault();

      const touch = _event.changedTouches[0];

      if (touch) {
        this.touch.joystick.active = true;

        this.touch.joystick.touchIdentifier = touch.identifier;

        this.touch.joystick.angle.current.x = touch.clientX;
        this.touch.joystick.angle.current.y = touch.clientY;

        this.touch.joystick.$limit.style.opacity = "0.5";

        window.addEventListener(
          "touchend",
          this.touch.joystick.events.touchend
        );
        window.addEventListener(
          "touchmove",
          this.touch.joystick.events.touchmove,
          { passive: false }
        );

        // this.trigger("joystickStart");
      }
    };

    this.touch.joystick.events.touchmove = (_event) => {
      // _event.preventDefault();

      const touches = [..._event.changedTouches];
      const touch = touches.find(
        (_touch) => _touch.identifier === this.touch.joystick.touchIdentifier
      );

      if (touch) {
        this.touch.joystick.angle.current.x = touch.clientX;
        this.touch.joystick.angle.current.y = touch.clientY;

        // this.trigger("joystickMove");
      }
    };

    this.touch.joystick.events.touchend = (_event) => {
      const touches = [..._event.changedTouches];
      const touch = touches.find(
        (_touch) => _touch.identifier === this.touch.joystick.touchIdentifier
      );

      if (touch) {
        this.touch.joystick.active = false;

        this.touch.joystick.$limit.style.opacity = "0.25";

        this.touch.joystick.$cursor.style.transform =
          "translateX(0px) translateY(0px)";

        window.removeEventListener(
          "touchend",
          this.touch.joystick.events.touchend
        );

        // this.trigger("joystickEnd");
      }
    };

    this.touch.joystick.$element.addEventListener(
      "touchstart",
      this.touch.joystick.events.touchstart,
      { passive: false }
    );

    /**
     * action buttoms container
     */
    this.touch.actionButtons = {};
    this.touch.actionButtons.$element = document.createElement("div");
    this.touch.actionButtons.$element.style.position = "fixed"; // Make the div a positioning root
    this.touch.actionButtons.$element.style.bottom = "0";
    this.touch.actionButtons.$element.style.right = "0";
    this.touch.actionButtons.$element.style.width = "200px"; // Adjust size accordingly
    this.touch.actionButtons.$element.style.height = "200px"; // Adjust size accordingly
    this.touch.actionButtons.$element.style.userSelect = "none";
    document.body.appendChild(this.touch.actionButtons.$element);

    // Function to create a button
    function createButton(image = null, label = null, size, pos, touchStart, touchEnd) {
      let button = document.createElement("button");
      
      button.style.position = "absolute";
      button.style.width = size + "px";
      button.style.height = size + "px";
      button.style.right = pos[0] + "px";
      button.style.bottom = pos[1] + "px";
      button.addEventListener("touchstart", touchStart);
      button.addEventListener("touchend", touchEnd);

      button.style.border = "2px solid rgba(255, 255, 255, 0.25)";
      button.style.borderRadius = "50%";
      button.style.backgroundColor = "transparent";
      button.style.backgroundColor = "rgba(255, 255, 255, 0.15)";
      button.style.opacity = "0.7";
      button.style.color = "white";
      button.style.outline = "none";
      button.style.boxShadow = "0 0 5px rgba(255, 255, 255, 0.5)";
      if (image) {
        button.style.backgroundImage = `url(${image})`
        button.style.backgroundSize = 'cover'
      }
      if (label) {
        button.innerText = label;
      }
      button.ontouchstart = function () {
        button.style.backgroundColor = "rgba(255, 255, 255, 0.5)";
      };
      button.ontouchend = function () {
        button.style.backgroundColor = "rgba(255, 255, 255, 0.25)";
      };
      return button;
    }

    const ACTION_BUTTONS_INFOS = [
      {
        size: 45,
        position: [70, 80],
        image: './textures/sprint-icon.png',
        touchStart: (e) => {
          e.preventDefault();
          this.keys.shift = true;
        },
        touchEnd: (e) => {
          e.preventDefault();
          this.keys.shift = false;
        },
      },
      {
        size: 45,
        position: [30, 120],
        image: './textures/jump-icon.png',
        touchStart: (e) => {
          e.preventDefault();
          this.keys.space = true;
        },
        touchEnd: (e) => {
          e.preventDefault();
          this.jumpLock = false; // prevent user hold the space
          this.keys.space = false;
        },
      },
      {
        size: 45,
        position: [110, 40],
        image: './textures/attack-icon.png',
        touchStart: (e) => {
          // e.preventDefault();
          // this.keys.interact = true;
        },
        touchEnd: (e) => {
          e.preventDefault();
          const focusDesk = window.gameManagers.reginTrackerManager.focusDesk;
          if (!focusDesk && !self.settingsPanelOpen) {
            window.gameManagers.weaponManager.handelMouseLeftClick();
          }
        },
      },
      {
        size: 45,
        position: [30, 40],
        label: "E",
        touchStart: (e) => {
          e.preventDefault();
          this.keys.interact = true;
        },
        touchEnd: (e) => {
          e.preventDefault();
          this.keys.interact = false;
        },
      },
    ];
    for (let i = 0; i < ACTION_BUTTONS_INFOS.length; i++) {
      let smallButton = createButton(
        ACTION_BUTTONS_INFOS[i].image,
        ACTION_BUTTONS_INFOS[i].label,
        ACTION_BUTTONS_INFOS[i].size,
        ACTION_BUTTONS_INFOS[i].position,
        ACTION_BUTTONS_INFOS[i].touchStart,
        ACTION_BUTTONS_INFOS[i].touchEnd
      );
      this.touch.actionButtons.$element.appendChild(smallButton);
    }

    // Reveal
    this.touch.reveal = () => {
      this.touch.joystick.$element.style.opacity = 1;
    };
  }

  update() {
    // Joystick active
    if (isMobile && this.touch?.joystick?.active) {
      // Calculate joystick angle
      this.touch.joystick.angle.originalValue = -Math.atan2(
        this.touch.joystick.angle.current.y -
          this.touch.joystick.angle.center.y,
        this.touch.joystick.angle.current.x - this.touch.joystick.angle.center.x
      );
      this.touch.joystick.angle.value =
        this.touch.joystick.angle.originalValue +
        this.touch.joystick.angle.offset;

      // Update joystick
      const distance = Math.hypot(
        this.touch.joystick.angle.current.y -
          this.touch.joystick.angle.center.y,
        this.touch.joystick.angle.current.x - this.touch.joystick.angle.center.x
      );
      let radius = distance;
      if (radius > 20) {
        radius = 20 + Math.log(distance - 20) * 5;
      }
      if (radius > 43) {
        radius = 43;
      }
      const cursorX =
        Math.sin(this.touch.joystick.angle.originalValue + Math.PI * 0.5) *
        radius;
      const cursorY =
        Math.cos(this.touch.joystick.angle.originalValue + Math.PI * 0.5) *
        radius;
      this.touch.joystick.$cursor.style.transform = `translateX(${cursorX}px) translateY(${cursorY}px)`;
    }
  }
}
