export const customAvatarShader = (material, uniforms) => {
  const customAvatarMaterial = material.clone();

  customAvatarMaterial.uniforms = uniforms;
  
  customAvatarMaterial.onBeforeCompile = shader => {
    shader.uniforms.eye = uniforms.eye;
    shader.uniforms.isDay = uniforms.isDay;
    shader.uniforms.sunPosition = uniforms.sunPosition;

    shader.vertexShader = shader.vertexShader.replace(
      `#include <uv_pars_vertex>`,
      `
      #include <uv_pars_vertex>
      varying vec3 vSurfaceNormal;
      varying vec3 vWorldPosition;
      `,
    );

    shader.vertexShader = shader.vertexShader.replace(
      `void main() {`,
      `
      void main() {
        vSurfaceNormal = normalize(mat3(modelMatrix[0].xyz, modelMatrix[1].xyz, modelMatrix[2].xyz) * normal);
      `,
    );
    shader.vertexShader = shader.vertexShader.replace(
      `#include <worldpos_vertex>`,
      `
      #include <worldpos_vertex>
      vWorldPosition = transformed;
      `,
    );

    

    shader.fragmentShader = shader.fragmentShader.replace(
      `#include <uv_pars_fragment>`,
      `
      #include <uv_pars_fragment>
      varying vec3 vSurfaceNormal;
      varying vec3 vWorldPosition;

      uniform vec3 sunPosition;
      uniform bool isDay;
      uniform vec3 eye;
      `,
    );
    
   
    shader.fragmentShader = shader.fragmentShader.replace(
      `vec4 diffuseColor = vec4( diffuse, opacity );`,
      `
        vec3 col = diffuse;

        vec3 lightPos = isDay ? sunPosition : -sunPosition;
        vec3 lightDir = normalize(lightPos);
        
        float lambertTerm = dot(vSurfaceNormal, lightDir);
        float specularPow = 5.0;
        vec3 E = normalize(eye - vWorldPosition);
        vec3 R = reflect(lightDir, vSurfaceNormal);
        float specularTerm = pow(max(dot(R, E), 0.0), specularPow);
        float EDotN = clamp(dot(E, vSurfaceNormal), 0.0, 1.0);

        vec3 ambientColor = vec3(0.1, 0.1, 0.1);
        vec3 lightColor = vec3(1.0);
        float diffuseStrength = 20.0;
        vec3 lightDiffuse = lightColor * vec3(lambertTerm * diffuseStrength);
        float specularMultiplier = 0.5;
        vec3 specular = lightColor * vec3(specularTerm * specularMultiplier);

        

        vec3 finalLight = ambientColor + lightDiffuse + specular;
        finalLight = clamp(finalLight, 0.5, 1.0);
        col *= finalLight;

        float sunShade = dot(vec3(0.0, 1.0, 0.0), - sunPosition);
        sunShade = sunShade * 0.5 + 0.5;
        vec3 shadeColor = col * vec3(0.3, 0.5, 0.7);
        col = mix(col, shadeColor, sunShade);



        vec4 diffuseColor = vec4( col, opacity );
      `,
    );
    // console.log(shader.vertexShader);
    // console.log(shader.fragmentShader);
  };
  return customAvatarMaterial;
}