const fireflyVertexShader = `\   
  uniform float uTime;
  uniform float size;
  uniform vec4 cameraBillboardQuaternion;


  attribute float scales;
  attribute float opacity;

  attribute vec3 positions;

  varying vec2 vUv;
  varying vec3 vWorldPosition;
  varying float vOpacity;


  vec3 rotateVecQuat(vec3 position, vec4 q) {
    vec3 v = position.xyz;
    return v + 2.0 * cross(q.xyz, cross(q.xyz, v) + q.w * v);
  }
  void main() {  
    vUv = uv;
    vOpacity = opacity;
    
    vec3 pos = position;
    pos = rotateVecQuat(pos, cameraBillboardQuaternion);
    pos *= scales;
    pos += positions;
    vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
    vWorldPosition = modelPosition.xyz;
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;
    gl_Position = projectionPosition;
    
  }
`;
const fireflyFragmentShader = `\
  uniform float uTime;
  uniform bool isDay;
  uniform sampler2D glowSpheretexture;
  uniform vec3 eye;
  varying vec2 vUv;
  varying float vOpacity;
  varying vec3 vWorldPosition;
  #define PI 3.1415926
  void main() {
    
    vec4 glowSphere = texture2D(glowSpheretexture, vUv);    
    gl_FragColor = glowSphere;
    float vtoP = distance(vWorldPosition, eye);
    float distanceLerp = clamp(10. - vtoP, 0., 10.);
    gl_FragColor.rgb = smoothstep(vec3(0.), vec3(distanceLerp), gl_FragColor.rgb);
    if (!isDay) {
      gl_FragColor.rgb *= vec3(0.970, 0.748, 0.0194);
      gl_FragColor.a *= vOpacity;
    }
    else {
      // gl_FragColor.rgb = smoothstep(vec3(0.), vec3(3.5), gl_FragColor.rgb);
      // gl_FragColor.a *= 0.6;
      discard;
    }

  }
`;
export {
  fireflyVertexShader, fireflyFragmentShader,
};