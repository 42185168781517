export const instanceGLBPath = {
  grassLod0: `./assets/instances/grassBlade1.glb`,
  grassLod1: `./assets/instances/grassLod1.glb`,
  flower: `./assets/instances/flower4.glb`,
  treeOneLod0: `./assets/instances/treeOneLod0.glb`,
  treeOneLod1: `./assets/instances/treeOneLod1.glb`,
  treeOneTrunk: `./assets/instances/treeOneTrunk.glb`,
  treeTwoLod0: `./assets/instances/treeTwoLod0.glb`,
  treeTwoLod1: `./assets/instances/treeTwoLod1.glb`,
  treeTwoTrunk: `./assets/instances/treeTwoTrunk.glb`,
  treeThreeLod0: `./assets/instances/treeThreeLod0.glb`,
  treeThreeLod1: `./assets/instances/treeThreeLod1.glb`,
  treeThreeTrunk: `./assets/instances/treeThreeTrunk.glb`,

  rockLod0: `./assets/instances/rockLod0.glb`,
  rockLod1: `./assets/instances/rockLod1.glb`,

  bushLod0: `./assets/instances/bushLod0.glb`,
  bushLod1: `./assets/instances/bushLod1.glb`,

  reed: `./assets/instances/reed3.glb`,
};

export const buildingGLBPath = {
  homespaceExterior: `./assets/building/homespace_exterior3.glb`,
  homespaceInterior: './assets/building/homespace_interior8.glb',
  homespaceInteriorPhysic: `./assets/building/homespace_interior_physic3.glb`,
  homespaceExteriorPhysic: `./assets/building/homespace_exterior_physic5.glb`,
};

export const weaponGLBPath = {
  sword: `./assets/weapons/sword2.glb`,
};

export const texturePacks = [
  {name: 'cloud1', texture: null, ext: 'png', repeat: false},
  {name: 'cloud2', texture: null, ext: 'png', repeat: false},
  {name: 'cloud3', texture: null, ext: 'png', repeat: false},
  {name: 'cloud4', texture: null, ext: 'png', repeat: false},

  {name: 'moon2', texture: null, ext: 'png', repeat: false},

  {name: 'star3', texture: null, ext: 'png', repeat: true},
  {name: 'noise', texture: null, ext: 'png', repeat: true},
  {name: 'noise2', texture: null, ext: 'png', repeat: true},
  {name: 'noise3', texture: null, ext: 'jpg', repeat: true},
  {name: 'noise4', texture: null, ext: 'jpg', repeat: true},

  {name: 'galaxy', texture: null, ext: 'png', repeat: false},
  {name: 'Flare32', texture: null, ext: 'png', repeat: false},
  {name: 'lensflare3', texture: null, ext: 'png', repeat: false},

  {name: 'waterNormal', texture: null, ext: 'png', repeat: true},

  {name: 'terrain-rock', texture: null, ext: 'png', repeat: true},
  {name: 'terrain-dirt', texture: null, ext: 'png', repeat: true},
  {name: 'terrain-sand', texture: null, ext: 'png', repeat: true},
  {name: 'terrain-grass', texture: null, ext: 'png', repeat: true},
  {name: 'terrain-brick', texture: null, ext: 'png', repeat: true},
  {name: 'terrain-brick-normal', texture: null, ext: 'png', repeat: true},

  {name: 'leaf1', texture: null, ext: 'png', repeat: false},
  {name: 'leaf2', texture: null, ext: 'png', repeat: false},
  {name: 'leaf3', texture: null, ext: 'png', repeat: false},
  {name: 'leaf4', texture: null, ext: 'png', repeat: false},
  {name: 'leaf5', texture: null, ext: 'png', repeat: false},
  {name: 'leaf6', texture: null, ext: 'png', repeat: false},
  {name: 'leaf7', texture: null, ext: 'png', repeat: false},

  {name: 'bark1', texture: null, ext: 'png', repeat: true},

  {name: 'stone', texture: null, ext: 'png', repeat: true},

  {name: 'noise3', texture: null, ext: 'png', repeat: true},

  {name: 'splash1', texture: null, ext: 'png', repeat: false},
  {name: 'splash2', texture: null, ext: 'png', repeat: false},
  {name: 'droplet', texture: null, ext: 'png', repeat: false},

  {name: 'splash3', texture: null, ext: 'png', repeat: false},
  {name: 'splash4', texture: null, ext: 'png', repeat: false},
  {name: 'splash5', texture: null, ext: 'png', repeat: false},

  {name: 'ripple', texture: null, ext: 'png', repeat: false},

  {name: 'flying-leaf', texture: null, ext: 'png', repeat: false},

  {name: 'butterfly-body', texture: null, ext: 'png', repeat: false},
  {name: 'butterfly-wing', texture: null, ext: 'png', repeat: false},

  {name: 'glowSphere', texture: null, ext: 'png', repeat: false},

  {name: 'sakura', texture: null, ext: 'png', repeat: false},
];