import * as THREE from 'three'

import View from '../View.js';
import { getLeaf } from '../environment-objects/leaf.js';
import { getButterflies } from '../environment-objects/butterflies.js';
import { getFireflies } from '../environment-objects/fireflies.js';
import { getSakura } from '../environment-objects/sakura.js';

export default class EnvironmentManager {
  constructor() {
    this.view = View.getInstance();
    this.skyManager = this.view.skyManager
    this.player = this.view.player;
    this.camera = this.view.camera;
    this.scene = this.view.instancedScene;
    this.texturePacks = this.view.texturePacks;

    this.globalUniforms = {
      sunPosition: {
        value: new THREE.Vector3()
      },
    }
    
    this.leaf = getLeaf(20, this.player, this.texturePacks, this.globalUniforms);
    this.scene.add(this.leaf);
    
    this.butterflies = getButterflies(10, this.player, this.texturePacks, this.globalUniforms);
    this.scene.add(this.butterflies);
    
    this.fireflies = getFireflies(100, this.player, this.camera, this.texturePacks);
    this.scene.add(this.fireflies);

    this.sakura = getSakura(15, this.player, this.texturePacks, this.globalUniforms);
    this.scene.add(this.sakura);
    
    window.hooks.on('current_region', data => {
      // Handle the event
      const landObjectVisisble = (data !== 'homespace_interior' && data !== 'beach')
      this.leaf.visible = landObjectVisisble;
      this.butterflies.visible = landObjectVisisble;
      this.fireflies.visible = landObjectVisisble;
      this.sakura.visible = landObjectVisisble;
      // console.log('current_region:', data);
    });
  }


  update(timestamp) {

    this.leaf.update(timestamp);
    this.butterflies.update(timestamp, this.skyManager.isDay);
    this.fireflies.update(timestamp, this.skyManager.isDay);
    this.sakura.update(timestamp);
    this.globalUniforms.sunPosition.value.copy(this.skyManager.sunPosition);
  }
}